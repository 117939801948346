<template>
<div id="app">
  <v-app id="inspire">
      <v-data-table :headers="headers" :items="desserts"
                    sort-by="calories" 
                    v-sortable-table>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>My CRUD</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="flex-grow-1"></div>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
  
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.name" label="Dessert name"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.calories" label="Calories"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.fat" label="Fat (g)"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.carbs" label="Carbs (g)"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.protein" label="Protein (g)"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
  
                <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>

    <v-select
      v-model="selectedFruits"
      :items="fruits"
      label="Favorite Fruits"
    >
      <template v-slot:prepend-item>
        <v-list-tile>
          <v-text-field label="Search" @input="searchFruit" />
        </v-list-tile>
      </template>
    </v-select>
  </v-app>
</div>
</template>

<script>
const allFruits = [
  'Apples',
  'Apricots',
  'Avocado',
  'Bananas',
  'Blueberries',
  'Blackberries',
  'Boysenberries',
  'Bread fruit',
  'Cantaloupes (cantalope)',
  'Cherries',
  'Cranberries',
  'Cucumbers',
  'Currants',
  'Dates',
  'Eggplant',
  'Figs',
  'Grapes',
  'Grapefruit',
  'Guava',
  'Honeydew melons',
  'Huckleberries',
  'Kiwis',
  'Kumquat',
  'Lemons',
  'Limes',
  'Mangos',
  'Mulberries',
  'Muskmelon',
  'Nectarines',
  'Olives',
  'Oranges',
  'Papaya',
  'Peaches',
  'Pears',
  'Persimmon',
  'Pineapple',
  'Plums',
  'Pomegranate',
  'Raspberries',
  'Rose Apple',
  'Starfruit',
  'Strawberries',
  'Tangerines',
  'Tomatoes',
  'Watermelons',
  'Zucchini'
];

function setNewPos(parentNode, oldIndex, oldNode, newIndex, newNode) {
  setTimeout(() => {
    if (oldIndex < newIndex) {
      parentNode.insertBefore(oldNode, newNode.nextSibling);
    } else {
      parentNode.insertBefore(oldNode, newNode);
    }
  }, 150);
}

// Add back the sortHandle class if it gets stripped away by external code
function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

function sortTableRows(el) {
  const options = {
    handle: '.sortHandle',
    animation: 150,
    onStart: (evt) => {
      // Flag all cells in the column being dragged by adding a class to them
      el.querySelectorAll('tr').forEach((row) => {
        const draggedTd = row.querySelectorAll('td')[evt.oldIndex];
        if (draggedTd) {
          draggedTd.classList.add('sorting');
        }
      });
    },
    onEnd: (evt) => {
      // Take the dragged cells and move them over to the new column location
      el.querySelectorAll('tr').forEach((row) => {
        if (!row.querySelector('th')) {
          const oldNode = row.querySelector('.sorting');
          const newNode = row.querySelectorAll('td')[evt.newIndex];
          setNewPos(row, evt.oldIndex, oldNode, evt.newIndex, newNode);
          oldNode.classList.remove('sorting');
        }
      });
    },
  };
  const initEl = el.getElementsByTagName('thead')[0].getElementsByTagName('tr')[0];
  return Sortable.create(initEl, options);
}

export default {
  el: '#app',
  data: () => ({
    dialog: false,
    headers: [
      { text: 'Dessert (100g serving)', value: 'name' },
      { text: 'Calories', value: 'calories' },
      { text: 'Fat (g)', value: 'fat' },
      { text: 'Carbs (g)', value: 'carbs' },
      { text: 'Protein (g)', value: 'protein' },
      { text: 'Actions', value: 'action', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: { name: '', calories: 0, fat: 0, carbs: 0, protein: 0 },
    defaultItem: { name: '', calories: 0, fat: 0, carbs: 0, protein: 0 },
    searchKey: '',
    fruits: allFruits,
    selectedFruits: []
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
  },

  created () {
    this.initialize();
  },

  methods: {
    searchFruit(val) {
      if (val) {
        this.fruits = this.fruits.filter(fruit => fruit.toLowerCase().indexOf(val) !== -1)
      } else {
        this.fruits = allFruits;
      }
    },
    initialize () {
      this.desserts = [
        { name: 'Frozen Yogurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
        { name: 'Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
        { name: 'Eclair', calories: 262, fat: 16.0, carbs: 23, protein: 6.0 },
      ]
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) { 
      const index = this.desserts.indexOf(item)
      this.desserts.splice(index, 1)
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
  },
  directives: {
    'sortable-table': {
      componentUpdated: (el) => {
        sortTableRows(el);
      },
      bind: (el) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
        sortTableRows(el);
      },
    },
  },
}
</script>