<template>
    <div class="content" style="scroll: auto;">
          <div class="row">
            <div class="col-1 px-1 py-0">
            Regiones:
              <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
                <el-option
                  v-for="item in regiones"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
            <div class="col-1 px-1 py-0" v-if="gerencias.length != 0">
            <span v-if="gerencias.length != 0">Gerencias:</span>
              <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
                <el-option
                  v-for="item in gerencias"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
            <div class="col-1 px-1 py-0" v-if="cedis.length != 0">
              <span >Cedis:</span>
              <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" >
                <el-option
                  v-for="item in cedis"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
            <div class="col-1 px-1 py-0">
              <span>Usuarios:</span>
              <el-select v-model="usuario" placeholder="Usuarios" size="mini">
                <el-option
                  v-for="item in usuarios"
                  :key="item.i+'us'"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
            <div class="col-1 px-1 py-0">
              Estatus:
              <el-select v-model="status" placeholder="ESTATUS" size="mini">
                <el-option
                  v-for="item in arrStatus"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
              <!--Source:
              <el-select v-model="source" placeholder="SOURCE" size="mini">
                <el-option
                  v-for="item in sources"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>-->
            <div class="col-2 px-1 py-0">
              Fechas:
                <el-date-picker
                  type="daterange"
                  placeholder="Date Time Picker"
                  v-model="dateTimePicker"
                  size="mini"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                  prefix-icon="false"
                  class="el-input__icon"
                  range-separator="A"
                  style="font-size:8px; padding:3px 0px 3px 6px;"
                >
                </el-date-picker>
            </div>
            <div class="col-1 px-1 pt-0">
              &nbsp;<br/>
              <el-button size="mini" type="primary" style="width:100%"
              @click="filtrarData"
              >
                Filtrar
              </el-button>
            </div>
            <div class="col-2 px-1 pt-0" v-if="permisos.add && lvl == 16">
              &nbsp;<br/>
              <el-button size="mini" type="primary" style="width:100%" 
              @click="callAsigarRegistro"
              >
                Asignar Registro
              </el-button>
            </div>
            <div class="col-1 px-0 pt-0">
              &nbsp;<br/>
              <el-dropdown  @command="handleCommand">
                <el-button size="mini" >
                  Acciones<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <!--<el-dropdown-item command="callAsignar" v-if="permisos.add && lvl == 16">Asignar Registro</el-dropdown-item>-->
                  <el-dropdown-item command="crear" v-if="permisos.add">Crear Nuevo</el-dropdown-item>
                  <el-dropdown-item command="filtrarIds" v-if="permisos.add && lvl == 255" >Filtrar por ID's</el-dropdown-item>
                  <el-dropdown-item command="filtrarPhone" v-if="permisos.del">Buscar Cliente</el-dropdown-item>
                  <el-dropdown-item command="downDataVal" v-if="permisos.edit && lvl == 255 ">Descargar validados</el-dropdown-item>
                  <el-dropdown-item command="downDataNoVal" v-if="permisos.edit && lvl == 255 ">Descargar No validados</el-dropdown-item>
                  <el-dropdown-item command="downDataCasVal" v-if="permisos.edit">Descargar Cast validados</el-dropdown-item>
                  <el-dropdown-item command="downDataCasNoVal" v-if="permisos.edit && lvl == 255 ">Descargar Validados+NO</el-dropdown-item>
                  <el-dropdown-item command="asignarRegistros" v-if="permisos.edit && lvl == 255 ">Asignar Supervisor</el-dropdown-item>
                  <el-dropdown-item command="reSet" v-if="permisos.edit && lvl == 255 && (uid == 1720 || uid == 4 || uid == 1)">Reset Registros</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="col-1 px-1 pt-0" v-if="permisos.edit && lvl == 255 && (uid == 1718 || uid == 1720 || uid == 660 || uid == 1)">
              &nbsp;<br/>
              <el-button size="mini" type="primary" style="width:100%" 
              @click="reAsignarAc"
              >
                Re - Asignar
              </el-button>
            </div>
          </div>

    <div class="row">
      <div :class="maxim">
        <v-card
          elevation="1"
          loading
          outlined
          style="padding:20px;"
        >
          <div class="row">
          <div class="col-12">
            <div class="div-flex">
              <div style="float: left; width: 70%">Registros por asignar: <b> {{ porAsignar }}</b> |
              Pre-Validados por asignar: <b> {{ prePorAsignar }}</b></div>
              <div class="text-right" style="float: right; width: 30%">
                <v-btn
                  color="white"
                  icon
                  @click="maximizar"
                >
                  <v-icon v-if="!actMax">mdi-chevron-double-right</v-icon>
                  <v-icon v-if="actMax">mdi-chevron-double-left</v-icon>
                </v-btn>
              </div>
            </div>
            <v-data-table
              dense
              :headers="headers"
              :items="desserts"
              item-key="car_id"
              class="elevation-1"
              :item-class="rowClass"
              :options="pagination"
              hide-default-footer
              @click:row="eventRowBus"
              single-select
              v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
            >
            <template v-slot:item.acciones="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="eventDblRow(item)"
                >
                  mdi-pencil
                </v-icon>
                </template>
              <template v-slot:item.valCar_val_status="{ item }" >
                {{
                  item.valCar_val_status == 1 ? 'ALTA'
                    : item.valCar_val_status == 2 ? 'RECHAZADO'
                    : item.valCar_val_status == 3 ? 'NUEVO'
                    : item.valCar_val_status == 4 ? 'PENDIENTE'
                    : item.valCar_val_status == 5 ? 'INCONSISTENCIA'
                    : item.valCar_val_status == 6 ? 'PEN. POR VALIDAR'
                    : item.valCar_val_status == 8 ? 'CONFIRMADO'
                    : item.valCar_val_status == 9 ? 'PROGRESO'
                    : item.valCar_val_status == 10 ? 'PRE-VALIDADO'
                    : 'Por Revisar' }}
              </template>
              <!--<template v-slot:item.car_foto1="{ item }" >
                {{ item.car_foto1 !== '' ? 'Sí' : 'No' }}
              </template>-->
              <template v-slot:item.gps_y="{ item }">
              -{{ item.gps_y }}
              </template>
            </v-data-table>
            <div
              slot="footer"
              class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
                </p>
              </div>
              <b-pagination
                v-model="pagination.currentPage"
                :per-page="pagination.limit"
                :total-rows="total"
                size="sm"
                @change="newPagina"
                ></b-pagination>
            </div>
          </div>
          </div>
        </v-card>
      </div>
      <div class="col-6 px-0" v-if="!actMax">
        <v-card
          elevation="1"
          loading
          outlined
          style="padding:20px"
        >
        <v-overlay :value="loadBus" absolute>
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
          <div class="row">
            <div class="col col-md-3 pr-2">
              <span>Ingresa Teléfono:</span> <el-input v-model="matchTelefono" placeholder="Teléfono" size="mini"></el-input>
            </div>
            <div class="col col-md-3 pr-2">
              Ingresa Nombre: <el-input v-model="matchNombre" placeholder="Nombre" size="mini"></el-input>
            </div>
            <div class="col col-md-3 pr-2">
              Ingresa Calle: <el-input v-model="matchCalle" placeholder="Calle" size="mini"></el-input>
            </div>
            <div class="col col-md-2">
              &nbsp;<br/>
              <el-button @click="filterMatch" type="primary" size="mini">Filtrar</el-button>
            </div>
          </div>
        <div class="row">
          <div class="col col-md-12">
          <v-data-table
                loading
                dense
                :headers="headersBus"
                :items="dessertsBus"
                item-key="valCar_id"
                class="elevation-1"
                :item-class="rowClass"
                hide-default-footer
                height="200"
              >
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ pagMatch.start + 1 }}</b> A <b>{{ pagMatch.start + pagMatch.itemsT }}</b> de <b>{{ pagMatch.total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagMatch.currentPage"
              :per-page="pagMatch.limit"
              :total-rows="pagMatch.total"
              size="sm"
              @change="newPaginaMatch"
              ></b-pagination>
          </div>
          </div>
        </div>
        </v-card>
      </div>
      <div class="col-1 px-0" v-if="actMax">
        <v-card
          elevation="1"
          loading
          outlined
          style="padding:20px;"
        >
        <div class="vertical-text" ><h2>Registros Coincidencias </h2></div>
        </v-card>
      </div>
    </div>

<!--------------------------- :visible.sync="dgFilterIds"-->
    <el-dialog
      title="Filtrar por ID's"
      :visible.sync="dgFilterIds"
      v-if="dgFilterIds"
      :destroy-on-close="true"
      width="30%">
        <div data-app>
          <p>Ingrese los Ids</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Ids separados por coma (,)"
            v-model="idsFiltrar"
            class="pb-4">
          </el-input>
          <div class="text-right">
            <el-button @click="dgFilterIds = false">Cancelar</el-button>
            <el-button type="primary" @click="getData">Filtrar</el-button>
          </div>
        </div>
    </el-dialog>
    <el-dialog
      title="Buscar Cliente"
      :visible.sync="dgFilterPhone"
      v-if="dgFilterPhone"
      width="30%">
        <div data-app>
          <p>Ingrese el Número Telefónico </p>
          <el-input
            placeholder="Número Telefónico"
            v-model="phoneFilter"
            class="pb-4">
          </el-input>
          <div class="text-right">
            <el-button @click="dgFilterPhone = false">Cancelar</el-button>
            <el-button type="primary" @click="getData">Buscar</el-button>
          </div>
        </div>
    </el-dialog>
    <el-dialog
      title="Re-Asignar Registros"
      :visible.sync="dgReAsignar"
      width="30%">
        <div data-app>
          <v-select v-model="callCenter" :items="itemsCallCenter"
            :rules="[v => !!v || 'Seleccione un Call Center']"
            label="Call Center"
            item-value="i"
            item-text="d"
            required
          ></v-select>
          <p>Ingrese los Ids</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Ids separados por coma (,)"
            v-model="idsAsignar"
            class="pb-4">
          </el-input>
          <div class="text-right">
            <el-button @click="dgReAsignar = false">Cancelar</el-button>
            <el-button type="primary" @click="reAsignar">Asignar</el-button>
          </div>
        </div>
    </el-dialog>
    <el-dialog
      title="Asignar Registros"
      :visible.sync="dgAsignarReg"
      width="35%"
      v-if="dgAsignarReg"
      >
      <div data-app>
          <v-form ref="asignarReg" v-model="asignarRegDg" lazy-validation >
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              Región:
                <el-select v-model="asigSuper.region" placeholder="REGIONES" size="mini" @change="getGerencias(3)">
                  <el-option
                    v-for="item in superRegiones"
                    :key="item.d"
                    :label="item.d"
                    :value="item.i">
                  </el-option>
                </el-select>
            </div>
            <div class="col-6 pl-2">
              Gerencia:
              <el-select :disabled="superGerencias.length == 0" v-model="asigSuper.gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(3)">
                <el-option
                  v-for="item in superGerencias"
                  :key="item.d"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              Cedi:
              <el-select id="cediAsig" name="cediAsig" :disabled="superCedis.length == 0" v-model="asigSuper.cedi" placeholder="CEDIS" size="mini" >
                <el-option
                  v-for="item in superCedis"
                  :key="item.d"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
            <div class="col-6 pl-2">
              <span>CAS:</span>
              <el-select v-model="asigSuper.usuario" placeholder="Usuarios" size="mini" @change="getAsignar(1)">
                <el-option
                  v-for="item in usuarios"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              Fechas:
              <el-date-picker
                v-if="dgAsignarReg"
                id="dateAsig"
                name="dateAsig"
                type="daterange"
                placeholder="Date Time Picker"
                v-model="asigSuper.dateTime"
                size="mini"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                prefix-icon="false"
                class="el-input__icon"
                range-separator="A"
                style="font-size:8px; padding:3px 0px 3px 6px;"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <br/>
              Por Asignar: <b><span style="font-size:20px"> {{ porAsignarData }} </span></b>
            </div>
            <div class="col-6 pl-2">
              <span>Asignar:</span>
                <el-input
                  type="number"
                  placeholder=""
                  v-model="asigSuper.asignar"
                  size="mini"
                  min="0"
                  onkeyup="value=value.replace(/[^\d]/g,0)"
                  :disabled="porAsignarData == 0"
                  >
                </el-input>
            </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="asignarSuper" :disabled="asigSuper.asignar == '' && asigSuper.asignar == 0" >Asignar Registros</el-button>
                </div>
              </div>
      </v-form>
      </div>
    </el-dialog>

    <v-overlay :value="overlay" z-index="2050">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- NUEVO CLIENTE-->
    <!--:visible.sync="showAdd"-->
    <el-dialog
      :destroy-on-close="true"
      title="Nuevo Cliente"
      width="70%"
      top="20px"
      :before-close="cerrarNuevo"
      id="formNuevo"
      :visible.sync="showAdd"
      v-if="showAdd"
      >
      <div data-app>
        <v-form ref="nwClient" v-model="nuevoValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-3">
              <v-text-field dense
                v-model="clNuevo.nud" label="NUD Cliente"
                style="font-size:11px"
                required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters text-center">
            <div class="col-12">
              <b>ENCUESTA</b>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-2 pr-1">
            <v-text-field dense
              v-model="clNuevo.folio" label="Volante/¿Cuál es su folio?"
              style="font-size:11px"
              required ></v-text-field>
            </div>
            <div class="col-2 pr-1">
              <v-text-field dense
                v-model="clNuevo.lona"
                style="font-size:11px"
                label="Lona SMS" required ></v-text-field>
            </div>
            <div class="col-2 pr-1">
              <v-text-field dense
                v-model="clNuevo.internet"
                style="font-size:11px"
                label="Internet" required ></v-text-field>
            </div>
            <div class="col-2 pr-1">
              <v-text-field dense
                v-model="clNuevo.otro"
                style="font-size:11px"
                label="Otro ¿Cuál?" required ></v-text-field>
            </div>
            <div class="col-4 pr-1">
              <v-text-field dense
                v-model="clNuevo.garrConsumo"
                style="font-size:11px"
                label="¿Cuántos garrafones consume por semana?" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-1">
                <v-text-field dense
                  v-model="clNuevo.maraConsume"
                  style="font-size:11px"
                  label="¿Qué marca de agua consume?" required ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  type="number"
                  min="0"
                  v-model="clNuevo.garrMix"
                  style="font-size:11px"
                  label="¿Garrafones mixtos?" required ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clNuevo.garrPaga"
                  style="font-size:11px"
                  prefix="$"
                  label="¿Cuánto paga por un garrafón?" required ></v-text-field>
              </div>
              <div class="col-3 ">
                <v-text-field dense
                  v-model="clNuevo.aceptPrecio"
                  style="font-size:11px"
                  label="Acepta precio" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clNuevo.consNuestro"
                  style="font-size:11px"
                  label="Actualmente ¿Consume nuestro garrafón?" required ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clNuevo.garrDonde"
                  style="font-size:11px"
                  label="Usualmente ¿Dónde compra su garrafón?" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters text-center">
              <div class="col-12">  
                <b>GENERAL</b>
              </div>  
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  id="cl_new-nombre"
                  v-model="clNuevo.nombre"
                  style="font-size:11px"
                  :rules="nameRules" label="Nombre" required
                  :counter="50"
                  maxlength="50"
                  @blur="v => cleanString(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clNuevo.telCel"
                  id="cl_new-telCel"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 1 celular"
                  :error-messages="tlDupCl"
                  :rules="[v => !!v || siRules || 'Escriba un teléfono']"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  id="cl_new-telFijo"
                  v-model="clNuevo.telFijo"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  :error-messages="tlDup"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  label="Teléfono 2 fijo"  ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clNuevo.correo"
                  style="font-size:11px"
                  :error-messages="messageEmailN"
                  @blur="v => valEmailN(v.target.value)"
                  label="Correo"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clNuevo.direccion"
                  id="cl_new-direccion"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una dirección']"
                  @blur="v => cleanString(v.target.id, v.target.value)"
                  label="Dirección" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clNuevo.nExt"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba Número']"
                  label="Num. Exterior" required ></v-text-field>
              </div>
              <div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clNuevo.nInt"
                  style="font-size:11px"
                  label="Num. Interior" ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clNuevo.manzana"
                  style="font-size:11px" label="Manzana" ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clNuevo.lote"
                  style="font-size:11px"
                  label="Lote" ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clNuevo.local"
                  style="font-size:11px"
                  label="Local" ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <!--<v-text-field dense
                  v-model="clNuevo.colonia"
                  id="cl_nuevo-colonia"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una colonia']"
                  @blur="v => cleanString(v.target.id, v.target.value)"
                  label="Colonia" required ></v-text-field>-->
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clNuevo.calle1"
                  id="cl_new-calle1"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una calle']"
                  @blur="v => cleanString(v.target.id, v.target.value)"
                  label="Calle 1" required ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clNuevo.calle2"
                  id="cl_new-calle2"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una calle']"
                  @blur="v => cleanString(v.target.id, v.target.value)"
                  label="Calle 2" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clNuevo.gpsX"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba latitud']"
                  label="GPS LAT (+)" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clNuevo.gpsY"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba longitud']"
                  label="GPS LNG (-)" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
            <div class="col-2 pr-2">
              <v-select
                v-model="clNuevo.cp"
                :rules="[v => !!v || siRules || 'Sel... un CP']"
                :items="itemsCps"
                item-value="i"
                item-text="d"
                label="CP"
                style="font-size:11px"
                dense
                @change="getDataFromCps()"
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchCp" />
                  </v-list-item-title>
                </template>
              </v-select>
            </div>
            <div class="col-1 pr-2" v-if="isOtro">
              <v-text-field dense
                v-model="clNuevo.cpOt"
                :rules="[v => !!v || siRules || 'Sel... un CP']"
                style="font-size:11px"
                label="CP" required
                ></v-text-field>
            </div>
            <div class="col-3 pr-2">
              <v-text-field dense
                :rules="[v => !!v || siRules || 'Sel... un Estado']"
                v-model="clNuevo.estado"
                style="font-size:11px"
                label="Estado" required v-if="!isOtro" :disabled="!isOtro"
                ></v-text-field>
              <v-select
                :rules="[v => !!v || siRules || 'Sel... un Estado']"
                v-model="clNuevo.estado"
                :items="itemsEdos"
                item-value="d"
                item-text="d"
                label="Estados"
                style="font-size:11px"
                dense
                @change="v => getDataMun(v)"
                v-if="isOtro"
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchEdo" />
                  </v-list-item-title>
                </template>
              </v-select>
            </div>
            <div class="col-3 pr-2">
              <v-select v-model="clNuevo.municipio" :items="itemsMunicipios"
                :rules="[v => !!v || siRules || 'Sel... un Municipio']"
                label="Municipio"
                item-value="d"
                item-text="d"
                style="font-size:11px"
                dense
                @change="v => getDataCol(v)"
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchMun" />
                  </v-list-item-title>
                </template>
              </v-select>
              <!--<v-text-field dense
                v-model="clNuevo.municipio"
                style="font-size:11px"
                label="Municipio" required v-if="isOtro"
                id="cl_new-municipio"
                @blur="v => cleanString(v.target.id, v.target.value)"
                ></v-text-field>-->
            </div>
            <div class="col-3 pr-2">
              <v-select
                :rules="[v => !!v || siRules || 'Sel... una Colonia']"
                v-model="clNuevo.colonia"
                :items="itemsColonias"
                item-value="d"
                item-text="d"
                label="Colonia"
                style="font-size:11px"
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchCol" />
                  </v-list-item-title>
                </template>
              </v-select>
              <!--<v-text-field dense
                v-model="clNuevo.colonia"
                style="font-size:11px"
                label="Colonia" required v-if="isOtro"></v-text-field>-->
            </div>
          </div>
          <div class="row no-gutters">
              <div class="col-2 pr-2">
                  <v-select v-model="clNuevo.intLlamadas" :items="itemsIntentos"
                    label="Intentos de llamada"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    dense
                    disabled
                  ></v-select>
              </div>
              <div class="col-2 pr-2">
                <v-select v-model="clNuevo.frecuencia" :items="itemsFrecuencia"
                    :rules="[v => !!v || siRules || 'Sel... una frecuencia']"
                    label="Frecuencia"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
              <div class="col-2 pr-2">
                <v-select v-model="clNuevo.source" :items="itemsSources"
                    :rules="[v => !!v || siRules || 'Sel... un source']"
                    label="Source"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
              <div class="col-2 pr-2">
                <v-select v-model="clNuevo.forma" :items="itemsForma"
                    :rules="[v => !!v || siRules || 'Sel... una forma']"
                    label="Forma"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
              <div class="col-4 pr-2">
                <v-select v-model="clNuevo.comentario" :items="itemsComentarios"
                    :rules="[v => !!v || siRules || 'Sel... un comentario']"
                    label="Comentario"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="v => validaComent(v)"
                  ></v-select>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clNuevo.otroComentario"
                  :disabled="disOtro"
                  style="font-size:11px"
                  label="Otro comentario" :required="!disOtro" ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-select v-model="clNuevo.region" :items="itemsRegiones"
                    :rules="[v => !!v || siRules || 'Sel... Región']"
                    label="Región"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="getGerencias(1)"
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clNuevo.gerencia" :items="itemsGerencias"
                    :rules="[v => !!v || siRules || 'Sel... Gerencia']"
                    label="Gerencia"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    dense
                    :disabled="!clNuevo.region"
                    @change="getCedis(1)"
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clNuevo.cedis" :items="itemsCedis"
                    :rules="[v => !!v || siRules || 'Sel... Cedis']"
                    label="Cedis"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    :disabled="!clNuevo.gerencia"
                    dense
                  ></v-select>
              </div>
          </div>
          <div class="row no-gutters">
            <div class="col-2 pr-2">
                <v-text-field dense
                  type="number"
                  min="0"
                  v-model="clNuevo.ruta"
                  style="font-size:11px"
                  label="Ruta" required ></v-text-field>
            </div>
              <div class="col-3 pr-2">
                <v-select v-model="clNuevo.estatus" :items="itemsStatus"
                    :rules="[v => !!v || siRules || 'Sel... Estatus']"
                    label="Estatus"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="v => validaStatus(v)"
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clNuevo.razon" :items="itemsRazon"
                    :rules="[v => !!v || disRazon || 'Sel... Razón']"
                    label="Razón"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    :required="disRazon"
                    dense
                    :disabled="disRazon"
                  ></v-select>
              </div>
              <div class="col-4 pr-2">
                Guardo los teléfonos?
                  <v-radio-group
                    v-model="clNuevo.guarda_tel"
                    row
                  >
                    <v-radio
                      label="SÍ"
                      value="1"
                      color="primary"
                    ></v-radio>
                    <v-radio
                      label="NO"
                      value="0"
                      color="primary"
                    ></v-radio>
                  </v-radio-group>
                <!--<el-switch
                  v-model="clNuevo.guarda_tel"
                  class="ml-2"
                  inline-prompt
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                  active-text="Sí"
                  inactive-text="No"
                />-->
              </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="validaCrearNuevo" :disabled="!siGuarda">Enviar Información</el-button>
                </div>
              </div>
            </v-form>
      </div>
    </el-dialog>

    <!--END NUEVO CLIENTE -->

    <!-- EDITAR CLIENTE :visible.sync="showEdit" -->
    <el-dialog
      :visible.sync="showEdit"
      :destroy-on-close="true"
      title="Editar Cliente"
      width="70%"
      top="20px"
      :before-close="cerrarNuevo"
      id="FormEditar"
      name="formEditar"
      index="form_editar"
      v-if="showEdit"
      >
      <div data-app>
        <v-form ref="editClient" v-model="editValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-3">
              <v-text-field dense
                v-model="clEditar.nud" label="NUD Cliente"
                style="font-size:11px"
                required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters text-center">
            <div class="col-12">
              <b>ENCUESTA</b>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-2 pr-1">
            <v-text-field dense
              v-model="clEditar.folio" label="Volante/¿Cuál es su folio?"
              style="font-size:11px"
              required ></v-text-field>
            </div>
            <div class="col-2 pr-1">
              <v-text-field dense
                v-model="clEditar.lona"
                style="font-size:11px"
                label="Lona SMS" required ></v-text-field>
            </div>
            <div class="col-2 pr-1">
              <v-text-field dense
                v-model="clEditar.internet"
                style="font-size:11px"
                label="Internet" required ></v-text-field>
            </div>
            <div class="col-2 pr-1">
              <v-text-field dense
                v-model="clEditar.otro"
                style="font-size:11px"
                label="Otro ¿Cuál?" required ></v-text-field>
            </div>
            <div class="col-4 pr-1">
              <v-text-field dense
                v-model="clEditar.garrConsumo"
                style="font-size:11px"
                label="¿Cuántos garrafones consume por semana?" required ></v-text-field>
            </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-1">
                <v-text-field dense
                  v-model="clEditar.maraConsume"
                  style="font-size:11px"
                  label="¿Qué marca de agua consume?" required ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  type="number"
                  min="0"
                  v-model="clEditar.garrMix"
                  style="font-size:11px"
                  label="¿Garrafones mixtos?" required ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.garrPaga"
                  style="font-size:11px"
                  prefix="$"
                  label="¿Cuánto paga por un garrafón?" required ></v-text-field>
              </div>
              <div class="col-3 ">
                <v-text-field dense
                  v-model="clEditar.aceptPrecio"
                  style="font-size:11px"
                  label="Acepta precio" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.consNuestro"
                  style="font-size:11px"
                  label="Actualmente ¿Consume nuestro garrafón?" required ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.garrDonde"
                  style="font-size:11px"
                  label="Usualmente ¿Dónde compra su garrafón?" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 text-left" style="font-size:11px">  
                <b>Codigo Promoción: </b>{{ clEditar.codigo }}
              </div>
              <div class="col-8 text-center">  
                <b>GENERAL</b>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  id="cl_edit-nombre"
                  v-model="clEditar.nombre"
                  style="font-size:11px"
                  :rules="nameRules" label="Nombre" required
                  :counter="50"
                  maxlength="50"
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.telCel"
                  id="cl_edit-telCel"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 1 celular"
                  :error-messages="tlDupCl"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  id="cl_edit-telFijo"
                  v-model="clEditar.telFijo"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  :error-messages="tlDup"
                  @blur="v => cleanNumber(v.target.id, v.target.value)"
                  label="Teléfono 2 fijo"  ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.correo"
                  style="font-size:11px"
                  :rules="mailRules"
                  :error-messages="messageEmail"
                  @blur="v => valEmail(v.target.value)"
                  label="Correo"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.direccion"
                  id="cl_edit-direccion"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una dirección']"
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  label="Dirección" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clEditar.nExt"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba Número']"
                  label="Num. Exterior" required ></v-text-field>
              </div>
              <div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clEditar.nInt"
                  style="font-size:11px"
                  label="Num. Interior" ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.manzana"
                  style="font-size:11px" label="Manzana" ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.lote"
                  style="font-size:11px"
                  label="Lote" ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.local"
                  style="font-size:11px"
                  label="Local" ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <!--<v-text-field dense
                  v-model="clEditar.colonia"
                  id="cl_nuevo-colonia"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una colonia']"
                  @blur="v => cleanString(v.target.id, v.target.value)"
                  label="Colonia" required ></v-text-field>-->
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle1"
                  id="cl_edit-calle1"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una calle']"
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  label="Calle 1" required ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle2"
                  id="cl_edit-calle2"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba una calle']"
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  label="Calle 2" required ></v-text-field>
              </div>
              <!--<div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clEditar.cp"
                  id="cl_edit-cp"
                  style="font-size:11px"
                  :rules="rulesCp"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="CP" required ></v-text-field>
              </div>-->
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.gpsX"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba latitud']"
                  label="GPS LAT (+)" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.gpsY"
                  style="font-size:11px"
                  :rules="[v => !!v || siRules || 'Escriba longitud']"
                  label="GPS LNG (-)" required ></v-text-field>
              </div>
          </div>
	        <div class="row no-gutters">
            <div class="col-2 pr-2">
              <v-select
                v-model="clEditar.cp"
                :items="itemsCps"
                item-value="i"
                item-text="d"
                label="CP"
                style="font-size:11px"
                dense
                @change="getDataFromCpsEdt()"
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchCp" />
                  </v-list-item-title>
                </template>
              </v-select>
            </div>
            <div class="col-1 pr-2" v-if="isOtro">
              <v-text-field dense
                v-model="clEditar.cpOt"
                style="font-size:11px"
                label="CP" required
                ></v-text-field>
            </div>
            <div class="col-3 pr-2">
              <v-text-field dense
                v-model="clEditar.estadoTxt"
                style="font-size:11px"
                label="Estado" required v-if="!isOtro" :disabled="!isOtro"
                ></v-text-field>
              <v-select
                v-model="clEditar.estado"
                :items="itemsEdos"
                item-value="d"
                item-text="d"
                label="Estados"
                style="font-size:11px"
                dense
                @change="v => getDataMun(v)"
                v-if="isOtro"
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchEdo" />
                  </v-list-item-title>
                </template>
              </v-select>
            </div>
            <div class="col-3 pr-2">
              <v-select v-model="clEditar.municipio" :items="itemsMunicipios"
                label="Municipio"
                item-value="d"
                item-text="d"
                style="font-size:11px"
                dense
                @change="v => getDataCol(v)"
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchMun" />
                  </v-list-item-title>
                </template>
              </v-select>
              <!--<v-text-field dense
                v-model="clEditar.municipio"
                style="font-size:11px"
                label="Municipio" required v-if="isOtro"
                ></v-text-field>-->
            </div>
            <div class="col-3 pr-2">
              <v-select
                v-model="clEditar.colonia"
                :items="itemsColonias"
                item-value="d"
                item-text="d"
                label="Colonia"
                style="font-size:11px"
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item-title>
                    <v-text-field label="Buscar" @input="searchCol" />
                  </v-list-item-title>
                </template>
              </v-select>
              <!--<v-text-field dense
                v-model="clNuevo.colonia"
                style="font-size:11px"
                label="Colonia" required v-if="isOtro"></v-text-field>-->
            </div>
          </div>
          <div class="row no-gutters">
              <div class="col-2 pr-2">
                  <v-select v-model="clEditar.intLlamadas" :items="itemsIntentos"
                    label="Intentos de llamada"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    dense
                    disabled
                  ></v-select>
              </div>
              <div class="col-2 pr-2">
                <v-select v-model="clEditar.frecuencia" :items="itemsFrecuenciaEdt"
                    id="edtFrecuencia"
                    :rules="[v => !!v || siRules || 'Sel... una frecuencia']"
                    label="Frecuencia"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
              <!--<div class="col-2 pr-2">
                <v-select v-model="clEditar.source" :items="itemsSources"
                    :rules="[v => !!v || 'Sel... un source']"
                    label="Source"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>-->
              <div class="col-2 pr-2">
                <v-select v-model="clEditar.forma" :items="itemsForma"
                    :rules="[v => !!v || siRules || 'Sel... una forma']"
                    label="Forma"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
              <div class="col-4 pr-2">
                <v-select v-model="clEditar.comentario" :items="itemsComentariosEdt"
                    :rules="[v => !!v || siRules || 'Sel... un comentario']"
                    label="Comentario"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="v => validaComent(v)"
                  ></v-select>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.otroComentario"
                  :disabled="disOtro"
                  style="font-size:11px"
                  label="Otro comentario" :required="!disOtro" ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-select v-model="clEditar.region" :items="itemsRegionesEdt"
                    :rules="[v => !!v || 'Sel... Región']"
                    label="Región"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="getGerencias(2)"
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.gerencia" :items="itemsGerenciasEdt"
                    :rules="[v => !!v || 'Sel... Gerencia']"
                    label="Gerencia"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    :disabled="!clEditar.region"
                    @change="getCedis(2)"
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.cedis" :items="itemsCedisEdt"
                    :rules="[v => !!v || 'Sel... Cedis']"
                    label="Cedis"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    :disabled="!clEditar.gerencia"
                    dense
                  ></v-select>
              </div>
          </div>
          <div class="row no-gutters">
            <div class="col-2 pr-2">
                <v-text-field dense
                  type="number"
                  min="0"
                  v-model="clEditar.ruta"
                  style="font-size:11px"
                  label="Ruta" required ></v-text-field>
            </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.estatus" :items="itemsStatusEdt"
                    :rules="[v => !!v || siRules || 'Sel... Estatus']"
                    label="Estatus"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="v => validaStatusEdt(v)"
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.razon" :items="itemsRazonEdt"
                    :rules="[v => !!v || disRazonEdt || 'Sel... Razón']"
                    label="Razón"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    :required="disRazonEdt"
                    dense
                    :disabled="disRazonEdt"
                  ></v-select>
              </div>
              <div class="col-4 pr-2">
                Guardo los teléfonos?
                  <v-radio-group
                    v-model="clEditar.guarda_tel"
                    row
                    dense
                    :rules="[v => !!v  || 'Seleccione']"
                  >
                    <v-radio
                      label="SÍ"
                      value="1"
                      color="primary"
                    ></v-radio>
                    <v-radio
                      label="NO"
                      value="0"
                      color="primary"
                    ></v-radio>
                  </v-radio-group>
                <!--<el-switch
                  v-model="clEditar.guarda_tel"
                  class="ml-2"
                  inline-prompt
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                  active-text="Sí"
                  inactive-text="No"
                />-->
              </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="validaActualizar" :disabled="!siGuarda" >Actualizar Información</el-button>
                </div>
              </div>
            </v-form>
      </div>
    </el-dialog>
    <!-- END EDITAR CLIENT -->

    <el-dialog
      title="Reset Registros"
      :visible.sync="dgReset"
      width="30%">
      <el-upload
        class="upload-demo mb-3"
        :auto-upload="false"
        :on-change="resetArchivo"
        >
        <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
        <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
      </el-upload>
      <!--<el-input
        type="textarea"
        :rows="2"
        placeholder="Resultados"
        v-model="resultadoMasivo">
      </el-input>-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgReset = false">Cancelar</el-button>
        <el-button type="primary" @click="resetMasivos">Enviar</el-button>
      </span>
    </el-dialog>

    </div>
</template>

<script>

import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import axios from 'axios';
import { Modal } from 'src/components';
//src/pages/Gepp/call_center.vue
import { cps } from '../../util/estados_cps'
// import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";
import LoginFormVue from '../Forms/ValidationForms/LoginForm.vue';

const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

function setNewPos(parentNode, oldIndex, oldNode, newIndex, newNode) {
  setTimeout(() => {
    if (oldIndex < newIndex) {
      parentNode.insertBefore(oldNode, newNode.nextSibling);
    } else {
      parentNode.insertBefore(oldNode, newNode);
    }
  }, 150);
}

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

function sortTableRows(el) {
  const options = {
    handle: '.sortHandle',
    animation: 150,
    onStart: (evt) => {
      // Flag all cells in the column being dragged by adding a class to them
      el.querySelectorAll('tr').forEach((row) => {
        const draggedTd = row.querySelectorAll('td')[evt.oldIndex];
        if (draggedTd) {
          draggedTd.classList.add('sorting');
        }
      });
    },
    onEnd: (evt) => {
      // Take the dragged cells and move them over to the new column location
      el.querySelectorAll('tr').forEach((row) => {
        if (!row.querySelector('th')) {
          const oldNode = row.querySelector('.sorting');
          const newNode = row.querySelectorAll('td')[evt.newIndex];
          setNewPos(row, evt.oldIndex, oldNode, evt.newIndex, newNode);
          oldNode.classList.remove('sorting');
        }
      });
    },
  };
  const initEl = el.getElementsByTagName('thead')[0].getElementsByTagName('tr')[0];
  return Sortable.create(initEl, options);
}

export default {
  mixins: [cps],
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch
  },
  data() {
    return {
      overlay: false,
      headers: [
        { text: '', value: 'acciones', width: 15, sortable: false },
        { text: 'Call center asignado', value: 'valCar_call_center_uid', width: 250,  sortable: false },
        { text: 'Fecha Llego', value: 'valCar_upload_date', width: 150,  sortable: false },
        { text: 'Hora Llego', value: 'valCar_upload_time', width: 150,  sortable: false },
        { text: 'Estatus validación', value: 'valCar_val_status', width: 150,  sortable: false },
        { text: 'Intentos Llamada', value: 'valCar_intentos', width: 100,  sortable: false },
        { text: 'Intento 1', value: 'intento_date_time_1', width: 150,  sortable: false },
        { text: 'Intento 2', value: 'intento_date_time_2', width: 150,  sortable: false },
        { text: 'Intento 3', value: 'intento_date_time_3', width: 150,  sortable: false },
        { text: 'Intento 4', value: 'intento_date_time_4', width: 150,  sortable: false },
        { text: 'Intento 5', value: 'intento_date_time_5', width: 150,  sortable: false },
        { text: 'Días Espera', value: 'valCar_val_days', width: 100,  sortable: false },
        { text: 'Frecuencia', value: 'valCar_frec_vis', width: 100,  sortable: false },
        { text: 'Forma', value: 'valCar_auto_tele', width: 150,  sortable: false },
        { text: 'Nombre', value: 'valCar_nombre', width: 250,  sortable: false },
        { text: 'Dirección', value: 'valCar_dir', width: 250,  sortable: false },
        { text: 'Teléfono Celular', value: 'valCar_tel_mot', width: 100,  sortable: false },
        { text: 'Teléfono 2 Fijo', value: 'valCar_tel_int', width: 100,  sortable: false },
        { text: 'NUD', value: 'valCar_NUD', width: 100,  sortable: false },
        { text: 'Volante/¿Cúal es su folio?', value: 'valCar_enc_volante', width: 100,  sortable: false },
        { text: 'Lona SMS', value: 'valCar_enc_lona', width: 50,  sortable: false },
        { text: 'Internet', value: 'valCar_enc_internet', width: 50,  sortable: false },
        { text: 'Otro ¿Cúal?', value: 'valCar_enc_otro', width: 150,  sortable: false },
        { text: '¿Cuántos garrafones consume por semana?', value: 'valCar_enc_garr_consume', width: 200,  sortable: false },
        { text: '¿Qué marca de agua consume?', value: 'valCar_enc_marca_consume', width: 150,  sortable: false },
        { text: '¿Cuánto paga por un garrafon?', value: 'valCar_enc_paga_garr', width: 150,  sortable: false },
        { text: 'Acepto precio', value: 'valCar_enc_acep_precio', width: 150,  sortable: false },
        { text: '¿Cuántos integrantes viven en su hogar?', value: 'valCar_enc_inte_hogar', width: 150,  sortable: false },
        { text: 'Actualmente ¿consume nuestro garrafon?', value: 'valCar_enc_consu_nues_garr', width: 150,  sortable: false },
        { text: 'Usualmente ¿Dónde compra su garrafon?', value: 'valCar_enc_donde_com_garr', width: 150,  sortable: false },
        { text: 'Calle 1', value: 'valCar_cll_1', width: 200,  sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 200,  sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext', width: 100,  sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int', width: 100,  sortable: false },
        { text: 'Manzana', value: 'valCar_manzana', width: 100,  sortable: false },
        { text: 'Lote', value: 'valCar_lote', width: 100,  sortable: false },
        { text: 'Local', value: 'valCar_local', width: 100,  sortable: false },
        { text: 'C.P.', value: 'valCar_cp', width: 50,  sortable: false },
        { text: 'Colonia', value: 'valCar_col', width: 200,  sortable: false },
        { text: 'Comentario', value: 'valCar_comments', width: 200,  sortable: false },
        { text: 'Comentario Pre-Validado', value: 'valCar_com_pre', width: 200,  sortable: false },
        { text: 'Correo', value: 'valCar_email', width: 100,  sortable: false },
        { text: 'GPS_X (+)', value: 'gps_x', width: 150,  sortable: false },
        { text: 'GPS_Y (-)', value: 'gps_y', width: 150,  sortable: false },
        { text: 'Región', value: 'valCar_reg', width: 150,  sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 150,  sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 200,  sortable: false },
        { text: 'Ruta Preventa', value: 'valCar_rt_prev', width: 100,  sortable: false },
        { text: 'Guarda Teléfonos', value: 'varCar_guarda_tels', width: 50,  sortable: false },
        { text: 'ID', value: 'valCar_id', width: 150,  sortable: false },
        { text: 'Cartera ID', value: 'car_id', width: 150,  sortable: false },
        { text: 'ID Capen', value: 'valCar_id_refresco', width: 150,  sortable: false },
        { text: 'ID cartera Capenh', value: 'car_id_refresco', width: 150,  sortable: false },
        { text: 'Reset', value: 'valCar_reset', width: 100,  sortable: false }
        ],
      desserts: [],
      headersBus: [
		    { text: 'Validación ID', value: 'valCar_id' , width: 150,  sortable: false },
        { text: 'Intentos de Llamada', value: 'valCar_intentos' , width: 200,  sortable: false },
        { text: 'NUD', value: 'valCar_NUD' , width: 100,  sortable: false },
        { text: 'Estatus validacion', value: 'valCar_val_status', width: 180,  sortable: false },
        { text: 'Revisado GEPP', value: 'valCar_gepp_rev', width: 150,  sortable: false },
        { text: 'callCenter Asignado', value: 'valCar_nom_call', width: 250,  sortable: false },
        { text: 'Source', value: 'src_desc', width: 150,  sortable: false },
        { text: 'Operacion', value: 'valCar_oper' , width: 150,  sortable: false },
        { text: 'Region', value: 'valCar_reg' , width: 200,  sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia' , width: 200,  sortable: false },
        { text: 'Cedis', value: 'valCar_cds' , width: 200,  sortable: false },
        { text: 'Ruta Preventa', value: 'valCar_rt_prev' , width: 200,  sortable: false },
        { text: 'Nombre', value: 'valCar_nombre' , width: 250,  sortable: false },
        { text: 'Contacto Adicional', value: 'valCar_contacto_adicional' , width: 250,  sortable: false },
        { text: 'Direccion', value: 'valCar_dir' , width: 250,  sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext' , width: 120,  sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int' , width: 120,  sortable: false },
        { text: 'Manzana', value: 'valCar_manzana' , width: 120,  sortable: false },
        { text: 'Lote', value: 'valCar_lote' , width: 100,  sortable: false },
        { text: 'Local', value: 'valCar_local' , width: 100,  sortable: false },
        { text: 'Calle 1', value: 'valCar_cll_1' , width: 150,  sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2' , width: 150,  sortable: false },
        { text: 'Colonia', value: 'valCar_col' , width: 150,  sortable: false },
        { text: 'C.P.', value: 'valCar_cp' , width: 100,  sortable: false },
        { text: 'Telefono', value: 'valCar_tel_int' , width: 120,  sortable: false },
        { text: 'Telefono 2', value: 'valCar_tel_mot' , width: 120,  sortable: false },
        { text: 'Telefono 3', value: 'valCar_tel_3' , width: 120,  sortable: false },
        { text: 'Telefono 4', value: 'valCar_tel_4' , width: 120,  sortable: false },
        { text: 'Email', value: 'valCar_email' , width: 150,  sortable: false },
        { text: 'Comentario', value: 'valCar_comments' , width: 200,  sortable: false },
        { text: 'Frecuencia', value: 'valCar_frec_all' , width: 150,  sortable: false },
        { text: 'GPS', value: 'valCar_gps' , width: 200,  sortable: false },
        { text: 'Version', value: 'valCar_ver' , width: 100,  sortable: false },
        { text: 'Fecha envio', value: '' , width: 150,  sortable: false },
        { text: 'Hora envio', value: '' , width: 150,  sortable: false },
        { text: 'Fecha validación', value: 'valCar_tel_rev_date' , width: 170,  sortable: false },
        { text: 'Hora validación', value: 'valCar_tel_rev_time' , width: 150,  sortable: false },
        { text: 'Cartera ID', value: 'valCar_car_id' , width: 120,  sortable: false },
        { text: 'Match', value: 'valCar_match', width: 150,  sortable: false }
      ],
      dessertsBus: [],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 20,
        itemsPerPage: 20,
        currentPage: 1
      },
      pagMatch: {
        page: 1,
        start: 0,
        limit: 13,
        currentPage: 1,
        total: 0,
        itemsT: 0
      },
      isBusy: false,
      region: null,
      regiones: [],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      pre: null,
      preventas: [
        ],
      status: null,
      arrStatus: [
      { i: -1, d: 'Todos' },
      //{ i:  10, d: 'Pre validado' },
      { i:  6, d: 'Pend. por validar' },
      { i:  1, d: 'Validado' }
        ],
    //dateTimePicker: [],
    hash: '',
    uid: '',
    lvl: '',
    porAsignar: 0,
    prePorAsignar: 0,
    usuario: null,
    usuarios: [],
    dateTimePicker: [],
    dgBusca: false,
    matchTelefono: '',
    matchNombre: '',
    matchCalle: '',
    dgFilterIds: false,
    dgFilterPhone: false,
    dgReAsignar: false,
    idsFiltrar: null,
    phoneFilter: null,
    callCenter: null,
    itemsCallCenter: [],
    idsAsignar: null,
    dgAsignarReg: false,
    asignarRegDg: null,
    clNuevo: {
      estado: '',
      guarda_tel: null
    },
    porAsignarData: 0,
    showAdd: false,
    nuevoValid: true,
    nameRules: [
      v => !!v || 'Nombre Requerido'
    ],
    phoneRules: [
        v => /^\d+$/.test(v)||'Solo Números'
      ],
    siRules: true,
    rulesCp: [
      v => (v && v.length == 5) || this.siRules || 'Escriba un Número valido',
      v => /^\d+$/.test(v) || this.siRules ||'Solo Números'
    ],
    mailRules:[
      //v => /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(v) || 'Escribe un correo válido',
    ],
    itemsFrecuencia: [
      {i: 'L,J', d: 'Lu-Ju'},
      {i: 'M,V', d: 'Ma-Vi'},
      {i: 'X,S', d: 'Mi-Sa'}
    ],
    itemsFrecuenciaEdt: [
      {i: 'L,J', d: 'Lu-Ju'},
      {i: 'M,V', d: 'Ma-Vi'},
      {i: 'X,S', d: 'Mi-Sa'}
    ],
    itemsSources: [
      { i: 5, d: 'Whats' },
      { i: 4, d: 'Call Center' },
      { i: 6, d: 'Transfer' },
      { i: 7, d: 'Lona' }
    ],
    itemsForma: [
      { i: 1, d: 'Televenta' },
      { i: 2, d: 'Autoventa' }
    ],
    itemsComentarios: [
      { i: 'BAJO CONSUMO', d: 'BAJO CONSUMO' },
      /*{ i: 'NO ACEPTA MODELO', d: 'NO ACEPTA MODELO' },*/
      { i: 'NO ACEPTA MODELO TLV', d: 'NO ACEPTA MODELO TLV' },
      { i: 'COMPRA POR RELLENADORA', d: 'COMPRA POR RELLENADORA' },
      { i: 'RECHAZO POR NO CONTACTO', d: 'RECHAZO POR NO CONTACTO' },
      { i: 'CLIENTE YA EXISTE', d: 'CLIENTE YA EXISTE' },
      { i: 'NUM EQUIVOCADO (NO EXISTE)', d: 'NUM EQUIVOCADO (NO EXISTE)' },
      { i: 'CLIENTE INCORRECTO (No conocen a la persona)', d: 'CLIENTE INCORRECTO (No conocen a la persona)' },
      { i: 'BUZON DE VOZ', d: 'BUZON DE VOZ' },
      { i: 'FUERA DE COBERTURA', d: 'FUERA DE COBERTURA' },
      { i: 'CLIENTE DUPLICADO', d: 'CLIENTE DUPLICADO' },
      { i: 'Confirmado', d: 'Confirmado' },
      { i: 'PRE-VALIDADO', d: 'PRE-VALIDADO' }
    ],
    itemsComentariosEdt: [
      { i: 'BAJO CONSUMO', d: 'BAJO CONSUMO' },
      /*{ i: 'NO ACEPTA MODELO', d: 'NO ACEPTA MODELO' },*/
      { i: 'NO ACEPTA MODELO TLV', d: 'NO ACEPTA MODELO TLV' },
      { i: 'COMPRA POR RELLENADORA', d: 'COMPRA POR RELLENADORA' },
      { i: 'RECHAZO POR NO CONTACTO', d: 'RECHAZO POR NO CONTACTO' },
      { i: 'CLIENTE YA EXISTE', d: 'CLIENTE YA EXISTE' },
      { i: 'NUM EQUIVOCADO (NO EXISTE)', d: 'NUM EQUIVOCADO (NO EXISTE)' },
      { i: 'CLIENTE INCORRECTO (No conocen a la persona)', d: 'CLIENTE INCORRECTO (No conocen a la persona)' },
      { i: 'BUZON DE VOZ', d: 'BUZON DE VOZ' },
      { i: 'FUERA DE COBERTURA', d: 'FUERA DE COBERTURA' },
      { i: 'CLIENTE DUPLICADO', d: 'CLIENTE DUPLICADO' },
      { i: 'Confirmado', d: 'Confirmado' },
      { i: 'PRE-VALIDADO', d: 'PRE-VALIDADO' }
    ],
    aditionalComents: [
      {i: 'OTRO', d:'OTRO' },
      {i: 'NO QUIERE ESA FRECUENCIA', d:'NO QUIERE ESA FRECUENCIA' },
      {i: 'NO ACEPTA PRECIO', d:'NO ACEPTA PRECIO' },
      {i: 'NO ACEPTA DAR DATOS', d:'NO ACEPTA DAR DATOS' }
    ],
    aditionalComentsEdt: [
      {i: 'OTRO', d:'OTRO' },
      {i: 'NO QUIERE ESA FRECUENCIA', d:'NO QUIERE ESA FRECUENCIA' },
      {i: 'NO ACEPTA PRECIO', d:'NO ACEPTA PRECIO' },
      {i: 'NO ACEPTA DAR DATOS', d:'NO ACEPTA DAR DATOS' }
    ],
    itemsRazonEdt: [
      { i:  1, d: 'Bajo consumo' },
      { i:  2, d: 'No acepta modelo' },
      { i:  3, d: 'Llenadora' },
      { i:  5, d: 'Por no contacto' },
      { i:  6, d: 'Cliente ya existe' },
      { i:  7, d: 'Fuera de cobertura' },
      { i:  8, d: 'Cliente duplicado' }
    ],
    itemsRazon: [
      { i:  1, d: 'Bajo consumo' },
      { i:  2, d: 'No acepta modelo' },
      { i:  3, d: 'Llenadora' },
      { i:  5, d: 'Por no contacto' },
      { i:  6, d: 'Cliente ya existe' },
      { i:  7, d: 'Fuera de cobertura' },
      { i:  8, d: 'Cliente duplicado' }
    ],
    itemsStatus: [
      { i:  6, d: 'Pend. por validar' },
      { i:  2, d: 'Rechazado' },
      { i:  1, d: 'Validado' },
    ],
    itemsStatusEdt: [
      { i:  6, d: 'Pend. por validar' },
      { i:  2, d: 'Rechazado' },
      { i:  1, d: 'Validado' },
    ],
    itemsIntentos: [
      { i: 1, d: 'Primer Intento' },
      { i: 2, d: 'Segundo Intento' },
      { i: 3, d: 'Tercer Intento' },
      { i: 4, d: 'Cuarto Intento' },
      { i: 5, d: 'Quinto Intento' }
    ],
    disOtro: true,
    disOtroEdt: true,
    disRazon: true,
    disRazonEdt: true,
    itemsRegiones: [],
    itemsGerencias: [],
    actGerencia: true,
    itemsCedis: [],
    showEdit: false,
    editValid: true,
    clEditar: {
      estado: '',
      nombre: '',
      direccion: '',
      calle1: '',
      calle2: '',
      guarda_tel: null
    },
    itemsRegionesEdt: [],
    itemsGerenciasEdt: [],
    itemsCedisEdt:[],
    permisos: {},
    idInDB: 0,
    valCarId: '',
    carId: '',
    carNuevo: '',
    valCarIdRefresco: '',
    carIdRefresco: '',
    loadBus: false,
    asigSuper: {
      region: '',
      gerencia: '',
      cedi: '',
      usuario:  '',
      dateTime: '',
      asignar: 0
    },
    superRegiones: [],
    superGerencias: [],
    superCedis: [],
    maxim: 'col-6 px-0',
    actMax: false,
    tlDup: '',
    tlDupCl: '',
    siGuarda: false, //true
    dgReset: false,
    resetFile: null,
    itemsCps: [],
    itemsMunicipios: [],
    allMunicipios: [],
    itemsColonias: [],
    allColonias: [],
    allCps: [],
    isOtro: false,
    itemsEdos: [],
    allEdos: [],
    glbEstado: '',
    glbMunicipio: '',
    glbColonia: '',
    messageEmail: '',
    messageEmailN: ''
    //value4: true
    };
  },
  methods: {
    rowClass(item) {
      //if (!item) return
      if (item.valCar_val_days >= '1' ) return 'stanby'
      if (item.valCar_val_status == '1') return 'new'
      if (item.valCar_val_status == '2') return 'danger'
      if (item.valCar_val_status == '6') return 'pendint'
    },
    filtrarData(){
      if(this.pagination.currentPage != 1){
        this.pagination.page = 1
        this.pagination.currentPage = 1
        this.start = 0
      }
      this.phoneFilter = null
      this.idsFiltrar = null
      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      //this.isBusy = true
      this.overlay = true
      let json = {
        c: "CallCenterNewCapen",
        cmd: "getCallCenterData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        opr: 0,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        sts: this.status,
        userFil: this.usuario,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        //ids: this.idsFiltrar
      };
      this.dgFilterPhone = false
      this.dgFilterIds = false
      if(this.idsFiltrar){
        json.ids = this.idsFiltrar
        
      }else if(this.phoneFilter){
        json.find_number = this.phoneFilter
        
      }else{
        json.date_fr = this.dateTimePicker[0]
        json.date_to = this.dateTimePicker[1]
      }


      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data);
        this.overlay = false
        var resp = data.data.rows
        //this.items = resp;
        //this.pagination.rowsTotal = data.data.results

        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        //this.overlay = false
        //this.isBusy = false
        this.getAsignar(0)
      })
    },
    getRegiones(sc){

      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

        if(typeof(data.data.success) != "undefined" && !data.data.success){
            localStorage.clear()
            this.$router.push('login')
        }else{
          if(sc == 0){
            this.regiones = data.data
            //this.mdRegiones = data.data
            this.regiones.unshift({ i: -1, d: 'TODAS' })
            //this.mdRegiones.splice(0,1)
          }else if(sc == 1){
            this.itemsRegiones = data.data
          /*}else if(sc == 2){
            this.itemsRegionesEdt == data.data*/
          }else if(sc == 3){ //Re-asignar
            this.superRegiones = data.data
            this.superRegiones.unshift({ i: -1, d: 'TODAS' })
          }
        }
      })
    },
    getRegionesAs(){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "geppValida",
          cmd: "getRegionesJson",
          exec: "self",
          uid: this.uid,
          hash: this.hash
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          resolve(data.data)
        })
      })
      return respuesta
    },
    getGerencias(sc){
      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        return false
      }

      if(this.asigSuper && this.asigSuper.region == -1 && sc == 3){
        this.asigSuper.gerencia = null
        this.asigSuper.cedi = null
        this.superGerencias = []
        this.superCedis = []
        return false
      }
      
      var region = null

      if(sc == 0){
        region = this.region
      }else if(sc == 1){
        region = this.clNuevo.region
      }else if(sc == 2){
        region = this.clEditar.region
      }else if(sc == 3){
        region = this.asigSuper.region
      }
      
      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg:  region// this.region //? this.region : this.mdRegion ? this.mdRegion : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else if(sc == 1){
          this.actGerencia = false
          this.itemsGerencias = data.data
        }else if(sc == 2){
          this.itemsCedisEdt = []
          this.clEditar.gerencia = null
          this.itemsGerenciasEdt = data.data
        }else if(sc == 3){
          //this.superCedis = []
          this.asigSuper.gerencia = null
          this.superGerencias = data.data
          this.superGerencias.unshift({ i: -1, d: 'TODAS' })
        }
      })
    },
    getGerenciasAs(region){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "geppValida",
          cmd: "getGerenciasJson",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          reg:  region// this.region //? this.region : this.mdRegion ? this.mdRegion : null
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          //console.log(data.data);
          resolve(data.data)
        })
      })
    return respuesta
    },
    getCedis(sc){

      if(this.gerencia == -1){
        this.cedi = null
        this.cedis = []
        return false
      }

      if(this.asigSuper && this.asigSuper.gerencia == -1 && sc == 3){
        this.asigSuper.cedi = null
        this.superCedis = []
        return false
      }

      var gerencia = null

      if(sc == 0){
        gerencia = this.gerencia
      }else if(sc == 1){
        gerencia = this.clNuevo.gerencia
      }else if(sc == 2){
        gerencia = this.clEditar.gerencia
      }else if(sc == 3){
        gerencia = this.asigSuper.gerencia
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: gerencia
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else if(sc == 1){
          this.itemsCedis = data.data
        }else if(sc == 2){
          this.itemsCedisEdt = data.data
        }else if(sc == 3){
          this.asigSuper.cedi = null
          this.superCedis = data.data
          this.superCedis.unshift({ i: -1, d: 'TODOS' })
        }
      })
    },
    getCedisAs(ger){
      var respuesta = new Promise(resolve =>{
        let json ={
          c: "geppValida",
          cmd: "getCedisJson",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          ger: ger
        };

        axios.get(this.$api_url, {params: json})
        .then(data=>{
          //console.log(data.data);
          resolve(data.data)
        })
      })
      return respuesta

    },
    handleCommand(cm){
      switch (cm){
        case 'filtrarPhone':
          this.dgFilterPhone = true
          this.region = null
          this.gerencias = []
          this.gerencia = null
          this.cedis = []
          this.cedi = null
          this.status = null
          this.usuario = null
          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
            this.getData()
          }
        break
        case 'filtrarIds':
          this.dgFilterIds = true
          this.region = null
          this.gerencias = []
          this.gerencia = null
          this.cedis = []
          this.cedi = null
          this.status = null
          this.usuario = null
          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
            this.getData()
          }
        break
        case 'downDataVal':
          this.downloadDataVal(1, "getCallCenterDataDownload")
        break
        case 'downDataNoVal':
          this.downloadDataVal(0, "getCallCenterDataDownload")
        break
        case 'downDataCasVal':
          this.downloadDataVal(1, "getCallCenterDataDownloadCast")
        break
        case 'downDataCasNoVal':
          this.downloadDataVal(0, "getCallCenterDataAllDownload")
        break
        case 'reAsignar':
          this.getUsuarios(1)
          this.idsAsignar = '',
          this.callCenter = null
          this.dgReAsignar = true
        break
        case 'reSet':
          this.resetFile = null
          this.dgReset = true
        break
        case 'asignarRegistros':
          if(this.$refs.asignarReg){
            this.$refs.asignarReg.reset()
          }
          if(this.superRegiones.length == 0){
            this.getRegiones(3)
          }
          this.asigSuper.dateTime = this.dateTimePicker
          this.asigSuper.region = null
          this.asigSuper.gerencia = null
          this.superGerencias = []
          this.asigSuper.cedi = null
          this.superCedis = []
          this.asigSuper.usuario = null
          this.porAsignarData = 0
          this.asigSuper.asignar = 0
          this.dgAsignarReg = true
        break
        case 'crear':
          this.showAdd = true
          this.reloadCps()
          if(this.$refs.nwClient){
            this.$refs.nwClient.reset()
          }
          this.clNuevo = {}
          this.disOtro = true
          this.disRazon = true

          var idx = this.itemsRazon.findIndex(item => item.i == 9)
            if(idx != -1){
              this.itemsRazon.splice(idx, 1)
            }
          for(var a in this.aditionalComents){
            var idx = this.itemsComentarios.findIndex(item => item.i == this.aditionalComents[a].i)
            if(idx !== -1){
              this.itemsComentarios.splice(idx, 1)
            }
          }

          if(this.itemsRegiones.length == 0){
            this.getRegiones(1)
          }
         this.clNuevo.intLlamadas = 1
         //this.siRules = true
         this.siRules = false
         this.siGuarda = false
         this.tlDup = ""
         this.tlDupCl = ""

        break
        case 'callAsignar':

        break
      }
    },
    validaNumero(id, phone){

      if(phone == ""){
        return false
      }

      var ids = id.split('-')[1]
      if(ids == 'telFijo') this.tlDup = ""
      if(ids == 'telCel') this.tlDupCl = ""
      

      let json ={
        c: "CallCenterNewCapen",
        cmd: "validatePhoneNumber",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        phoneNumber: phone
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        
        //this.$refs.cl_edit_telFijo.valid = false
        //console.log(this.$refs.cl_edit_telFijo);
        
        this.siGuarda = true

        var resp = data.data

          if(resp.success){

            //if(this.siRules) this.siGuarda = false
            if(ids == 'telFijo') this.tlDup = "Duplicado"
            if(ids == 'telCel') this.tlDupCl = "Duplicado"
            
            this.$notify({
              title: '',
              message: 'Registro Duplicado, Este número esta duplicado, ID: ' + resp.data['0'] + ' NUD: ' + resp.data['1'] + ' Nombre: ' + resp.data['2'],
              verticalAlign: 'top',
              horizontalAlign: 'center',
              type: 'success',
              timeout: 10000,
              icon: 'tim-icons icon-bell-55'
            });
          }
      })
    },
    downloadDataVal(val, cmd){
      let json ={
        c: "CallCenterNewCapen",
        cmd: cmd,
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        val: val,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        userFil: this.usuario,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      var url = new URLSearchParams(json).toString()

      //console.log(this.$api_url + '?' + url);

      window.location = this.$api_url + '?' + url

      /*axios.get(this.$api_url, {params: json})
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })*/
    },
    getUsuarios(sc){
      let json ={
        c: "CallCenterNewCapen",
        cmd: "getCallCenterUsersJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 1){
          this.itemsCallCenter = data.data
        }else{
          this.usuarios = data.data
          this.usuarios.unshift({ i: -1, d: 'TODOS' })
        }
      })
    },
    getAsignar(sc){
      
      let region = 0
      let gerencia = 0
      let cedi = 0
      let d1 = ''
      let d2 = ''
      if(sc == 0){
        region = this.region
        gerencia = this.gerencia
        cedi = this.cedi
        d1 = this.dateTimePicker[0]
        d2 = this.dateTimePicker[1]
      }else if(sc == 1){
        region = this.asigSuper.region ? this.asigSuper.region : 0
        gerencia = this.asigSuper.gerencia ? this.asigSuper.gerencia : 0
        cedi = this.asigSuper.cedi ? this.asigSuper.cedi : 0
        d1 = this.asigSuper.dateTime ? this.asigSuper.dateTime[0] : ''
        d2 = this.asigSuper.dateTime ? this.asigSuper.dateTime[1] : ''
      }

      let json ={
        c: "CallCenterNewCapen",
        cmd: "restantes",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: region,
        ger_id: gerencia,
        cds_id: cedi,
        date_fr: d1,
        date_to: d2
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        //
        if(sc == 1){
          this.porAsignarData = data.data
        }else{
          this.porAsignar = data.data
        }
      })
    },
    getPreAsignar(){
      let json ={
        c: "CallCenterNewCapen",
        cmd: "restantesPre",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);

        this.prePorAsignar = data.data
        
      })
    },
    eventRowBus(record, row){
      this.reloadCps()
      row.select(true)
      this.loadBus = true
      //record = record
      this.idsFiltrar = record.valCar_id
      let json ={
        c: "CarteraIniGenerico",
        cmd: "getCartFinData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        car_id: record.car_id,
        car_id_refresco: record.car_id_refresco,
        valCar_NUD: record.valCar_NUD,
        valCar_gps: record.valCar_gps,
        valCar_id: record.valCar_id,
        valCar_nombre: record.valCar_nombre,
        valCar_tel_int: record.valCar_tel_int,
        valCar_tel_mot: record.valCar_tel_mot,
        valCar_dir: record.valCar_dir,
        valCar_num_ext: record.valCar_num_ext,
        valCar_gerencia: record.valCar_gerencia,
        valCar_gerencia_id: record.valCar_gerencia_id,
        page: this.pagMatch.page,
        start: this.pagMatch.start,
        limit: this.pagMatch.limit
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        var resp = data.data.rows
        this.dgBusca = true
        this.loadBus = false
        this.dessertsBus = data.data.rows
        this.pagMatch.total = data.data.results
        this.pagMatch.itemsT = resp.length
      })
    },
    async eventDblRow(r){
      console.log(r);
      this.showEdit = true
      this.tlDup = ''
      this.tlDupCl = ''
      this.siRules = true
      this.isOtro = false
      this.clEditar.cpOt = ''

      if(this.$refs.editClient){
        this.$refs.editClient.reset()
      }
      
      this.itemsRegionesEdt = await this.getRegionesAs()

      if(r.valCar_reg && r.valCar_reg != ''){
        var tmpreg = r.valCar_reg.toLowerCase();
        var fr = this.itemsRegionesEdt.find(r => r.d.toLowerCase() == tmpreg)
        this.clEditar.region = fr ? fr.i : ''
        this.itemsGerenciasEdt = await this.getGerenciasAs(fr.i)
        var fg = this.itemsGerenciasEdt.find(g => g.i == r.valCar_gerencia_id)
        this.clEditar.gerencia = fg ? fg.i : ''
        this.itemsCedisEdt = await this.getCedisAs(fg.i)
        var fcd = this.itemsCedisEdt.find(cd => cd.d.toLowerCase() == r.valCar_cds.toLowerCase())
        this.clEditar.cedis = fcd ? fcd.i : ''
      }
      
      if(r.valCar_comments && r.valCar_comments != '') {
        var fc = this.itemsComentariosEdt.find(c => c.i.toLowerCase() == r.valCar_comments.toLowerCase())
      }
      if(r.valCar_auto_tele && r.valCar_auto_tele != ''){
        var ff = this.itemsForma.find(f => f.d.toLowerCase() == r.valCar_auto_tele.toLowerCase())
      }
      if(r.valCar_val_status && r.valCar_val_status != ''){
        var st = this.itemsStatus.find(st => st.i ==  r.valCar_val_status)
      }
      if(r.valCar_intentos && r.valCar_intentos != ''){
        var inte = parseInt(r.valCar_intentos) + 1
        var ll = this.itemsIntentos.find(ll => ll.i == inte)
      }
      if(r.valCar_frec_vis && r.valCar_frec_vis != ''){
        var fre = this.itemsFrecuenciaEdt.find(fr => fr.i.toLowerCase() == r.valCar_frec_vis.toLowerCase()).i
      }

      if(r.valCar_cp && r.valCar_cp != '' && !isNaN(r.valCar_cp)){
        var data = await this.getDataFromCp(r.valCar_cp)
        if(r.valCar_edo != ''){
          //console.log(data.data_edo[0].d);
          this.clEditar.estadoTxt = data.data_edo.length != 0 ? data.data_edo[0].d : ''
        }
        //this.clEditar.municipio = 
        this.itemsMunicipios = data.data_mun.length != 0 ? data.data_mun : []
        this.itemsColonias = data.data_col.length != 0 ? data.data_col : []
        this.allColonias = data.data_col != 0 ? data.data_col : []
        //console.log(this.itemsMunicipios);
        if(r.valCar_edo && r.valCar_mun){
          var cps = this.itemsCps.find(cp => cp.d == parseInt(r.valCar_cp).toString()).i
          var muni = this.itemsMunicipios.find(mn => this.cleanAnyString(mn.d.toLowerCase()) == r.valCar_mun.toLowerCase()).d
          var cl = this.itemsColonias.find(col => this.cleanAnyString(col.d.toLowerCase()) == r.valCar_col.toLowerCase()).d
        }
        //console.log(this.allColonias);
      }

      this.clEditar.nud = r.valCar_NUD
      this.clEditar.folio = r.valCar_enc_volante
      this.clEditar.lona = r.valCar_enc_lona
      this.clEditar.internet = r.valCar_enc_internet
      this.clEditar.otro = r.valCar_enc_otro
      this.clEditar.garrConsumo = r.valCar_enc_garr_consume
      this.clEditar.maraConsume = r.valCar_enc_marca_consume
      this.clEditar.garrMix = r.valCar_garr_mix
      this.clEditar.garrPaga = r.valCar_enc_paga_garr
      this.clEditar.aceptPrecio = r.valCar_enc_acep_precio
      this.clEditar.consNuestro = r.valCar_enc_consu_nues_garr
      this.clEditar.garrDonde = r.valCar_enc_donde_com_garr
      this.clEditar.codigo = r.valCar_codigo_verificacion
      this.clEditar.nombre = r.valCar_nombre
      this.clEditar.telCel = r.valCar_tel_mot
      this.clEditar.telFijo = r.valCar_tel_int
      this.clEditar.correo = r.valCar_email
      this.clEditar.direccion = r.valCar_dir
      this.clEditar.nExt = r.valCar_num_ext
      this.clEditar.nInt = r.valCar_num_int
      this.clEditar.manzana = r.valCar_manzana
      this.clEditar.lote = r.valCar_lote
      this.clEditar.local = r.valCar_local
      this.clEditar.colonia = cl ? cl : null// r.valCar_col
      this.clEditar.calle1 = r.valCar_cll_1
      this.clEditar.calle2 = r.valCar_cll_2
      this.clEditar.estado = r.valCar_edo
      this.clEditar.municipio = muni ? muni : null//r.valCar_mun
      this.clEditar.cp = cps ? cps : r.valCar_cp
      this.clEditar.gpsX = r.gps_x
      this.clEditar.gpsY = '-' + r.gps_y
      this.clEditar.intLlamadas = ll ? ll.i : this.itemsIntentos[4].i
      this.clEditar.frecuencia = fre ? fre : null
      this.clEditar.forma = ff ? ff.i : null 
      this.clEditar.comentario = fc ? fc.i : null
      this.clEditar.otroComentario = fc ? '': r.valCar_comments
      this.clEditar.ruta = r.valCar_rt_prev
      this.clEditar.estatus = st ? st.i : ''
      this.siGuarda = st ? true : false
      this.clEditar.razon = ''
      this.clEditar.guarda_tel = r.varCar_guarda_tels == "SI" ? "1" : "0"

      this.valCarId = r.valCar_id
      this.carId = r.car_id
      this.carNuevo = r.car_nuevo
      this.valCarIdRefresco = r.valCar_id_refresco
      this.carIdRefresco = r.car_id_refresco

      //console.log(this.clEditar);
    },
    filterMatch(){
      this.overlay = true
      //console.log(record);
      let json ={
        c: "CarteraIniGenerico",
        cmd: "getCartFinData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        valCar_nombre_2: this.matchNombre,
        valCar_tel_int_2: this.matchTelefono,
        valCar_tel_mot_2: this.matchTelefono,
        valCar_dir: this.matchCalle,
        page: this.pagMatch.page,
        start: this.pagMatch.start,
        limit: this.pagMatch.limit
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        var resp = data.data.rows
        //this.dgBusca = true
        this.overlay = false
        this.dessertsBus = data.data.rows
        this.pagMatch.total = data.data.results
        this.pagMatch.itemsT = resp.length
        
      })
    },
    reAsignar(){
      let json ={
        c: "CallCenterNewCapen",
        cmd: "_reAsignarCallCenter",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        idReasign: this.idsAsignar,
        CallId: this.callCenter
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()

        this.dgReAsignar = false
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        
      })
    },
    validaCrearNuevo(){
      if(this.$refs.nwClient.validate()){
        //console.log(this.clNuevo.comentario);
        /*if(this.clNuevo.estatus == 2 && this.clNuevo.intLlamadas < 3){
          this.$notify({
            title: '',
            message: 'No puedes rechazar hasta el 3er intento.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else*/
        if(this.clNuevo.estatus == 1 && this.clNuevo.comentario == 'Confirmado'){
          this.crearNuevo()
        }else if(this.clNuevo.estatus == 1 && this.clNuevo.comentario != 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Validado solo acepta comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clNuevo.estatus == 2 && this.clNuevo.comentario != 'Confirmado' && this.clNuevo.comentario != "OTRO"){
          this.crearNuevo()
        }else if(this.clNuevo.estatus == 2 && this.clNuevo.comentario != 'Confirmado' && this.clNuevo.comentario == "OTRO" ){
          this.clNuevo.comentario = this.clNuevo.otroComentario
          this.crearNuevo()
        }else if(this.clNuevo.estatus == 2 && this.clNuevo.comentario == 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Estatus Rechazado no utiliza comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clNuevo.estatus != 1 && this.clNuevo.estatus != 2){
          this.crearNuevo()
        }else{
          this.$notify({
            title: '',
            message: 'Error al guardar la información.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }
    },
    validaActualizar(){
      if(this.$refs.editClient.validate()){
        // console.log(this.clNuevo.comentario);
        /*if(this.clEditar.estatus == 2 && this.clEditar.intLlamadas < 3){
          this.$notify({
            title: '',
            message: 'No puedes rechazar hasta el 3er intento.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else*/
        if(this.clEditar.estatus == 1 && this.clEditar.comentario == 'Confirmado'){
          this.actualizarCliente()
        }else if(this.clEditar.estatus == 1 && this.clEditar.comentario != 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Validado solo acepta comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clEditar.estatus == 2 && this.clEditar.comentario != 'Confirmado' && this.clEditar.comentario != "OTRO"){
          this.actualizarCliente()
        }else if(this.clEditar.estatus == 2 && this.clEditar.comentario != 'Confirmado' && this.clEditar.comentario == "OTRO" ){
          this.clEditar.comentario = this.clEditar.otroComentario
          this.actualizarCliente()
        }else if(this.clEditar.estatus == 2 && this.clEditar.comentario == 'Confirmado'){
          this.$notify({
            title: '',
            message: 'Estatus Rechazado no utiliza comentario Confirmado.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }else if(this.clEditar.estatus != 1 && this.clEditar.estatus != 2){
          this.actualizarCliente()
        }else{
          this.$notify({
            title: '',
            message: 'Error al guardar la información.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });
        }
      }
    },
    crearNuevo(){
     if(this.isOtro){
        let json ={
          c: "CallCenterNewCapen",
          cmd: "addNewCp",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          cp: this.clNuevo.cpOt,
          edo: this.cleanAnyString(this.clNuevo.estado),
          //clEdo: this.clNuevo.estado,
          mun: this.cleanAnyString(this.clNuevo.municipio),
          //clMun: this.clNuevo.municipio,
          col: this.cleanAnyString(this.clNuevo.colonia)
        };
        this.addNewCp(json)
      }
      this.overlay = true
      let json ={
        c: "CallCenterNewCapen",
        cmd: "sumbitChangesNewRegister",
        exec: "self",
        hash: this.hash,
        uid: this.uid,
        motIcn: this.clNuevo.razon ? this.clNuevo.razon : 0,
        stat: this.clNuevo.estatus,
        intentos: this.clNuevo.intLlamadas,
        chn: JSON.stringify(this.getAllData())
      }

      
      const queryString = new URLSearchParams(json).toString();
      //console.log(queryString);
      
      axios.post(this.$api_url+'?'+queryString)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.showAdd = false
          this.$notify({
            title: '',
            message: 'Se creo el registro con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()
        
      })

    },
    actualizarCliente(){
      if(this.isOtro){
        let json ={
          c: "CallCenterNewCapen",
          cmd: "addNewCp",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          cp: this.clEditar.cpOt,
          edo: this.cleanAnyString(this.clEditar.estado),
          //clEdo: this.clEditar.estado,
          mun: this.cleanAnyString(this.clEditar.municipio),
          //clMun: this.clEditar.municipio,
          col: this.cleanAnyString(this.clEditar.colonia)
        };
        this.addNewCp(json)
      }
      this.overlay = true
      let json = {
        c: "CallCenterNewCapen",
        cmd: "sumbitChanges",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        vid: this.valCarId,
        caid: this.carId,
        refid: this.valCarIdRefresco,
        refCarid: this.carIdRefresco,
        motIcn:  this.clEditar.razon ? this.clEditar.razon : 0,
        stat: this.clEditar.estatus,
        nue: this.carNuevo,
        intentos: this.clEditar.intLlamadas,
        chn: JSON.stringify(this.getAllDataEdt())
      };
      

      const queryString = new URLSearchParams(json).toString();

      axios.post(this.$api_url+'?'+queryString)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.showEdit = false
          this.$notify({
            title: '',
            message: 'Se actualizó el registro con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }
        this.idsFiltrar = null
        this.phoneFilter = null
        this.getData()
        
      })

    },
    getAllData(){
      /*if(this.clNuevo.estado && this.clNuevo.estado != '' && this.isOtro){
        var estado = this.itemsEdos.find(e => this.cleanAnyString(e.i.toString()) == this.clNuevo.estado.toString()).d
        this.glbEstado = estado
      }
      if(this.clNuevo.municipio && this.clNuevo.municipio != ''){
        var municipio = this.itemsMunicipios.find(m => this.cleanAnyString(m.i.toString()) == this.clNuevo.municipio.toString()).d
        this.glbMunicipio = municipio
      }*/
      return {
            wnd_edt_nud_New: this.clNuevo.nud ? this.clNuevo.nud : '',
            wnd_edt_enc_volante_New: this.clNuevo.folio ? this.clNuevo.folio : '',
            wnd_edt_enc_lona_New: this.clNuevo.lona ? this.clNuevo.lona : '',
            wnd_edt_enc_internet_New: this.clNuevo.internet ? this.clNuevo.internet : '',
            wnd_edt_enc_otro_New: this.clNuevo.otro ? this.clNuevo.otro : '',
            wnd_edt_enc_garr_consume_New: this.clNuevo.garrConsumo ? this.clNuevo.garrConsumo :'',
            wnd_edt_enc_marca_consume_New: this.clNuevo.maraConsume ? this.clNuevo.maraConsume : '',
            wnd_edt_garr_mix_New: this.clNuevo.garrMix ? this.clNuevo.garrMix : '',
            wnd_edt_enc_paga_garr_New: this.clNuevo.garrPaga ? this.clNuevo.garrPaga : '',
            wnd_edt_enc_acep_precio_New: this.clNuevo.aceptPrecio ? this.clNuevo.aceptPrecio : '',
            wnd_edt_enc_consu_nues_New: this.clNuevo.consNuestro ? this.clNuevo.consNuestro : '',
            wnd_edt_enc_donde_com_New: this.clNuevo.garrDonde ? this.clNuevo.garrDonde : '',
            wnd_edt_nme_New: 'BC ' + this.clNuevo.nombre,
            wnd_edt_tel_New: this.clNuevo.telCel ?  this.clNuevo.telCel : '',
            wnd_edt_tel_adi_New: this.clNuevo.telFijo ? this.clNuevo.telFijo : '',
            wnd_edt_corrV_New: this.clNuevo.correo ? this.clNuevo.correo : '',
            wnd_edt_dir_New: this.clNuevo.direccion,
            wnd_edt_next_New: this.clNuevo.nExt,
            wnd_edt_nint_New: this.clNuevo.nInt ? this.clNuevo.nInt : '',
            wnd_edt_mza_New: this.clNuevo.manzana ? this.clNuevo.manzana : '',
            wnd_edt_lote_New: this.clNuevo.lote ? this.clNuevo.lote : '',
            wnd_edt_loc_New: this.clNuevo.local ? this.clNuevo.local : '',
            wnd_edt_edo_New: this.clNuevo.estado ? this.cleanAnyString(this.clNuevo.estado) : '',
            wnd_edt_mun_New: this.clNuevo.municipio ? this.cleanAnyString(this.clNuevo.municipio) : '',
            wnd_edt_col_New: this.clNuevo.colonia ? this.cleanAnyString(this.clNuevo.colonia) : '',
            wnd_edt_cll1_New: this.clNuevo.calle1 ? this.clNuevo.calle1 : '',
            wnd_edt_cll2_New: this.clNuevo.calle2 ? this.clNuevo.calle2 : '',
            wnd_edt_cp_New: this.clNuevo.cp && this.clNuevo.cp !== -1 ? this.clNuevo.cp : this.clNuevo.cpOt,
            wnd_edt_gps_new_lat: this.clNuevo.gpsX,
            wnd_edt_gps_new_lng: this.clNuevo.gpsY,
            wnd_edt_gps_new: this.clNuevo.gpsX + ','+ this.clNuevo.gpsY,
            wnd_edt_frec_New: this.clNuevo.frecuencia,
            wnd_edt_sourse_New: this.clNuevo.source,
            wnd_edt_forma_New: this.clNuevo.forma,
            wnd_edt_com_New: this.clNuevo.comentario,
            wnd_edt_reg_name_New: this.clNuevo.region,
            wnd_edt_ger_name_New: this.clNuevo.gerencia,
            wnd_edt_cds_name_New: this.clNuevo.cedis,
            wnd_edt_rtp_New: this.clNuevo.ruta ? this.clNuevo.ruta : '',
            wnd_status_sus_New: this.clNuevo.estatus,
            wnd_edt_irz_New: this.clNuevo.razon ? this.clNuevo.razon : '',
            wnd_edt_guarda_tels_New: this.clNuevo.guarda_tel && this.clNuevo.guarda_tel == "1"  ? 1 : 0
          }
    },
    getAllDataEdt(){
      /*if(this.clEditar.estado && this.clEditar.estado != '' && this.isOtro){
        var estado = this.itemsEdos.find(e => this.cleanAnyString(e.i.toString()) == this.clEditar.estado.toString()).d
        this.glbEstado = estado
      }
      if(this.clEditar.municipio && this.clEditar.municipio != ''){
        var municipio = this.itemsMunicipios.find(m => this.cleanAnyString(m.i.toString()) == this.clEditar.municipio.toString()).d
        this.glbMunicipio = municipio
      }*/
      var estado = "";
      //console.log(this.clEditar.estadoTxt);
      if(this.clEditar.estado && this.clEditar.estado != ""){
        estado = this.cleanAnyString(this.clEditar.estado);
      }else if(this.clEditar.estadoTxt && this.clEditar.estadoTxt != ""){
        estado = this.cleanAnyString(this.clEditar.estadoTxt)
      }

      return  {
          wnd_edt_nud: this.clEditar.nud ? this.clEditar.nud : '',
          wnd_edt_enc_volante: this.clEditar.folio ?  this.clEditar.folio : '',
          wnd_edt_enc_lona: this.clEditar.lona ?  this.clEditar.lona : '',
          wnd_edt_enc_internet: this.clEditar.internet ?  this.clEditar.internet : '',
          wnd_edt_enc_otro: this.clEditar.otro ?  this.clEditar.otro : '',
          wnd_edt_enc_garr_consume: this.clEditar.garrConsumo ?  this.clEditar.garrConsumo : '',
          wnd_edt_enc_marca_consume: this.clEditar.maraConsume ?  this.clEditar.maraConsume : '',
          wnd_edt_garr_mix: this.clEditar.garrMix ?  this.clEditar.garrMix : '',
          wnd_edt_enc_paga_garr: this.clEditar.garrPaga ?  this.clEditar.garrPaga : '',
          wnd_edt_enc_acep_precio: this.clEditar.aceptPrecio ?  this.clEditar.aceptPrecio : '',
          wnd_edt_enc_consu_nues: this.clEditar.consNuestro ?  this.clEditar.consNuestro : '',
          wnd_edt_enc_donde_com: this.clEditar.garrDonde ?  this.clEditar.garrDonde : '',
          wnd_edt_nme: this.clEditar.nombre ? this.clEditar.nombre : '',
		      wnd_edt_tel: this.clEditar.telCel ?  this.clEditar.telCel : '',
          wnd_edt_tel_adi: this.clEditar.telFijo ?  this.clEditar.telFijo : '',
          wnd_edt_corrV: this.clEditar.correo ?  this.clEditar.correo : '',
          wnd_edt_dir: this.clEditar.direccion ? this.clEditar.direccion : '',
		      wnd_edt_nint: this.clEditar.nInt ?  this.clEditar.nInt : '',
		      wnd_edt_next: this.clEditar.nExt ? this.clEditar.nExt : '',
          wnd_edt_mza: this.clEditar.manzana ?  this.clEditar.manzana : '',
          wnd_edt_lote: this.clEditar.lote ?  this.clEditar.lote : '',
          wnd_edt_loc: this.clEditar.local ?  this.clEditar.local : '',
          wnd_edt_cll1: this.clEditar.calle1 ?  this.clEditar.calle1 : '',
          wnd_edt_cll2: this.clEditar.calle2 ?  this.clEditar.calle2 : '',
          wnd_edt_edo: estado,
          //wnd_edt_mun: municipio
          //wnd_edt_col: colonia
          //wnd_edt_edo: this.clEditar.estado ?  this.cleanAnyString(this.clEditar.estado) : this.cleanAnyString(this.clEditar.estadoTxt),
          wnd_edt_mun: this.clEditar.municipio ? this.cleanAnyString(this.clEditar.municipio) : '',
          wnd_edt_col: this.clEditar.colonia ? this.cleanAnyString(this.clEditar.colonia) : '',
          wnd_edt_cp: this.clEditar.cp && this.clEditar.cp !== -1 ?  this.clEditar.cp : this.clEditar.cpOt ? this.clEditar.cpOt : '',
          wnd_edt_com: this.clEditar.comentario ?  this.clEditar.comentario: '',
          wnd_edt_com_otro: this.clEditar.otroComentario ?  this.clEditar.otroComentario : '',
          wnd_status_sus: this.clEditar.estatus ?  this.clEditar.estatus : '',
          wnd_edt_irz: this.clEditar.razon ?  this.clEditar.razon : '',
          wnd_edt_frec: this.clEditar.frecuencia ? this.clEditar.frecuencia : '',
          wnd_edt_forma: this.clEditar.forma ? this.clEditar.forma : '',
          wnd_edt_cds_name: this.clEditar.cedis ? this.clEditar.cedis : '',
          wnd_edt_reg_name: this.clEditar.region ? this.clEditar.region : '',
          wnd_edt_ger_name: this.clEditar.gerencia ? this.clEditar.gerencia : '',
          wnd_edt_rtp: this.clEditar.ruta ? this.clEditar.ruta : '',
          wnd_edt_gps: this.clEditar.gpsX + ', ' +  this.clEditar.gpsY,
          wnd_edt_guarda_tels: this.clEditar.guarda_tel && this.clEditar.guarda_tel == "1" ? 1 : 0
      }
    },
    asignarSuper(){
      this.overlay = true
      let json = {
        c: "CallCenterNewCapen",
        cmd: "asignarSUpervisor",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: this.asigSuper.region ? this.asigSuper.region : null,
        ger_id: this.asigSuper.gerencia ? this.asigSuper.gerencia : null,
        cds_id: this.asigSuper.cedi ? this.asigSuper.cedi : null,
        date_fr: this.asigSuper.dateTime[0],
        date_to: this.asigSuper.dateTime[1],
        numasig: this.asigSuper.asignar ? this.asigSuper.asignar : 0,
        CallId: this.asigSuper.usuario
        //ids: this.idsFiltrar
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
            this.$notify({
              title: '',
              message: 'Se realizo la Asignación con éxito.',
              verticalAlign: 'top',
              horizontalAlign: 'center',
              type: 'success',
              timeout: 10000,
              icon: 'tim-icons icon-bell-55'
            });

            this.dgAsignarReg = false

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()

      })
    },
    cleanString(id, val){

      //console.log(id+' '+val);

      val = val.replace(/á/gi,"a");
      val = val.replace(/é/gi,"e");
      val = val.replace(/í/gi,"i");
      val = val.replace(/ó/gi,"o");
      val = val.replace(/ú/gi,"u");
      val = val.replace(/ñ/gi,"n");

      val = val.replace(/Á/gi,"a");
      val = val.replace(/É/gi,"e");
      val = val.replace(/Í/gi,"i");
      val = val.replace(/Ó/gi,"o");
      val = val.replace(/Ú/gi,"u");
      val = val.replace(/Ñ/gi,"n");
      var tmpId = id.split('-')[1]
      
        this.clNuevo[tmpId] =  val.trim()
    },
    cleanStringEdt(id, val){

      val = val.replace(/á/gi,"a");
      val = val.replace(/é/gi,"e");
      val = val.replace(/í/gi,"i");
      val = val.replace(/ó/gi,"o");
      val = val.replace(/ú/gi,"u");
      val = val.replace(/ñ/gi,"n");
      val = val.replace(/Á/gi,"a");
      val = val.replace(/É/gi,"e");
      val = val.replace(/Í/gi,"i");
      val = val.replace(/Ó/gi,"o");
      val = val.replace(/Ú/gi,"u");
      val = val.replace(/Ñ/gi,"n");
      var tmpId = id.split('-')[1]
      
      this.clEditar[tmpId] =  val.trim()

    },
    cleanNumber(id, val){
      //return false
      //console.log(id);
      var tmpId = id.split('-')[1]
       val = val.replace(/[^0-9]/g,'')
       this.clNuevo[tmpId] = val
      this.validaNumero(id, val)
     
    },
    cleanNumberEdt(id, val){
      //return false
      var tmpId = id.split('-')[1]
       val = val.replace(/[^0-9]/g,'')
       this.clEditar[tmpId] = val
      this.validaNumero(id, val)
     
    },
    validaComent(val){
      var idx = this.itemsRazon.findIndex(item => item.i == 9)
      if (val === 'NO ACEPTA MODELO TLV') {
        this.itemsRazon.push({i: '9', d:'E-commerce' })
      }else if(idx != -1){
        this.itemsRazon.splice(idx, 1)
      }

      if(val == 'OTRO'){
        this.disOtro = false
      }else {
        this.clNuevo.otroComentario = null
        this.disOtro = true
      }

    },
    validaStatus(val){
      console.log(val);
      if(val == 1){
        this.siRules = false
        this.siGuarda = false
        this.validaNumero('cl_new-telCel', this.clNuevo.telCel)
        }else{
          this.siRules = true
          this.siGuarda = true
          this.tlDupCl = ''
        }
        console.log(this.siRules);
      if(val == 2){
        this.disRazon = false
        for(var a in this.aditionalComents){
          this.itemsComentarios.unshift(this.aditionalComents[a])
        }
      } else {
        this.disRazon = true
        this.clNuevo.razon = null
        for(var a in this.aditionalComents){
          var idx = this.itemsComentarios.findIndex(item => item.i == this.aditionalComents[a].i)
          if(idx !== -1){
            this.itemsComentarios.splice(idx, 1)
          }
        }
      }
    },
    validaStatusEdt(val){
      this.siGuarda = true
      if(val == 1){
        this.siRules = false
        }else{
          this.siRules = true
        }
      if(val == 2){
        this.disRazonEdt = false
        for(var a in this.aditionalComentsEdt){
          this.itemsComentariosEdt.unshift(this.aditionalComentsEdt[a])
        }
      } else {
        this.disRazonEdt = true
        this.clEditar.razon = null
        for(var a in this.aditionalComentsEdt){
          var idx = this.itemsComentariosEdt.findIndex(item => item.i == this.aditionalComentsEdt[a].i)
          if(idx !== -1){
            this.itemsComentariosEdt.splice(idx, 1)
          }
        }
      }
    },
    newPagina(p){
      this.idsFiltrar = null
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    newPaginaMatch(p) {
      //console.log(p);
      this.pagMatch.page = p
      this.pagMatch.start = this.pagMatch.limit * (p - 1)
      this.pagMatch.currentPage = p

      this.filterMatch()
    },
    cerrarNuevo(){
      //var elementos = document.getElementsByClassName('el-dialog__headerbtn'); // ó :
      //var elementos = elementoRaiz.getElementsByClassName(nombres);
      var evento = window.event;
      //console.log(evento.target.classList[0]);
      if(evento.target.classList[0] == 'el-dialog__close' && this.showAdd){
        this.showAdd = false
      }
      if(evento.target.classList[0] == 'el-dialog__close' && this.showEdit){
        this.showEdit = false
      }
    },
   reAsignarAc(){
      this.getUsuarios(1)
      this.idsAsignar = '',
      this.callCenter = null
      this.dgReAsignar = true
      this.idsAsignar = this.idsFiltrar
    },
    resetArchivo(file){
      this.resetFile = file.raw
      console.log(file.raw);
    },
    resetMasivos(){
      this.overlay = true

      const formData = new FormData();
      formData.append('wnd_upre_xls', this.resetFile);
      //formData.append('frm_ini_act', 0);

      axios.post(this.$api_url + "?c=CallCenterNewCapen&cmd=massiveReset&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.dgReset = false
        if(data.data.success){
          this.$notify({
              title: '',
              message: 'Registros modificados con éxito.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }else{
          this.$notify({
              title: '',
              message: 'Existe un error.',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 4000,
              icon: 'tim-icons icon-bell-55'
            });
        }
      })
    },
    callAsigarRegistro(){
      this.overlay = true
      let json ={
        c: "CallCenterNewCapen",
        cmd: "AsignarRegistro",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

        //console.log(data.data);
        this.overlay = false
        var resp = data.data
          if(resp.success){
            this.$notify({
              title: '',
              message: 'Registro Asignado',
              verticalAlign: 'top',
              horizontalAlign: 'center',
              type: 'success',
              timeout: 5000,
              icon: 'tim-icons icon-bell-55'
            });
          }
          
          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()
      })
    },
    searchCp(val) {
      if (val) {
        this.itemsCps = this.itemsCps.filter(cp => cp.i.toString().indexOf(val) !== -1)
      } else {
        this.itemsCps = this.allCps
      }
    },
    searchCol(val) {
      if (val) {
        this.itemsColonias = this.itemsColonias.filter(col => col.d.toLowerCase().indexOf(val) !== -1)
      } else {
        this.itemsColonias = this.allColonias;
      }
    },
    searchEdo(val) {
      console.log(val);
      if (val) {
        this.itemsEdos = this.itemsEdos.filter(ed => ed.d.toLowerCase().indexOf(val) !== -1)
      } else {
        this.itemsEdos = this.allEdos;
      }
    },
    searchMun(val) {
      if (val) {
        this.itemsMunicipios = this.itemsMunicipios.filter(mn => mn.d.toLowerCase().indexOf(val) !== -1)
      } else {
        this.itemsMunicipios = this.allMunicipios;
      }
    },
    async getDataFromCps(){
     // console.log(this.clNuevo.cp);
     if(this.clNuevo.cp !== -1){
       this.isOtro = false
      var data = await this.getDataFromCp(this.clNuevo.cp)
      this.clNuevo.estado = data.data_edo[0].d
      this.itemsMunicipios = data.data_mun
      this.itemsColonias = data.data_col
      this.allColonias = data.data_col
     }else{
       this.isOtro = true
       /*this.clNuevo.estado = ''
       this.clNuevo.municipio = ''
       this.clNuevo.colonia = ''*/
       var dt = await this.getDataEdos()

       this.itemsEdos = dt.data_edos
       this.allEdos = dt.data_edos
     }
    },
    async getDataFromCpsEdt(){
      console.log(this.clEditar.cp);
     if(this.clEditar.cp !== -1){
       this.isOtro = false
      var data = await this.getDataFromCp(this.clEditar.cp)
      this.clEditar.estadoTxt = data.data_edo[0].d
      this.itemsMunicipios = data.data_mun
      this.itemsColonias = data.data_col
      this.allColonias = data.data_col
     }else{
       this.isOtro = true
       /*this.clEditar.estado = ''
       this.clEditar.municipio = ''
       this.clEditar.colonia = ''*/
       var dt = await this.getDataEdos()

       this.itemsEdos = dt.data_edos
       this.allEdos = dt.data_edos
     }
    },
    async getDataMun(val){
      this.clNuevo.municipio = null
      this.clEditar.municipio = null
      this.clNuevo.colonia = null
      this.clEditar.colonia = null
      this.itemsMunicipios = []
      this.itemsColonias = []
      var dt = await this.getDataMuns(this.cleanAnyString(val))
      this.itemsMunicipios = dt.data_muns
      this.allMunicipios = dt.data_muns
    },
    async getDataCol(val){
      if(this.isOtro){
        this.clNuevo.colonia = null
        this.clEditar.colonia = null
        this.itemsColonias = []
        var dt = await this.getDataCols(this.cleanAnyString(val))
        this.itemsColonias = dt.data_cols
        this.allColonias = dt.data_cols
      }
    },
    maximizar(){
      //console.log('Maximizar');
      if(!this.actMax){
        this.maxim = 'col-11 px-0'
        this.actMax = true
      }else{
        this.maxim = 'col-6 px-0'
        this.actMax = false
      }
    },
    async reloadCps(){
      this.itemsCps = await this.getDataCps()
      this.itemsCps.unshift({i: -1, d: 'OTRO'})
      this.allCps = this.itemsCps
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },
    valEmail(v){
      if(v != ""){
        this.messageEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(v) ? '' : 'Escribe un correo válido'
      }else{
        this.messageEmail = ''
      }
    },
    valEmailN(v){
      if(v != ""){
        this.messageEmailN = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(v) ? '' : 'Escribe un correo válido'
      }else{
        this.messageEmailN = ''
      }
    }
  },
  mounted() {

    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')
      // console.log( JSON.parse(localStorage.getItem('permission')));
      this.permisos = JSON.parse(localStorage.getItem('permission')).call_center_new_capen.actions

      console.log(this.uid);
      if(this.uid == 1 || this.uid == 4 || this.uid == 1631){
        this.arrStatus.push({ i:  2, d: 'Rechazado' })
      }

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

        //console.log(this.permisos);
      this.getRegiones(0)
      this.getUsuarios(0)
      //this.getData()

      this.getPreAsignar()
      this.reloadCps()

      //console.log(this.itemsCps);

    } else {
       localStorage.clear()
       this.$router.push('login');
    }

  },
  directives: {
    'sortable-table': {
      componentUpdated: (el) => {
        sortTableRows(el);
      },
      bind: (el) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
        sortTableRows(el);
      },
    },
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}

/*tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}*/

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

.stanby {
  background-color:#F2F5A9;
}

.new {
  background-color:#45B39D;
}

.danger {
  background-color:#F78181;
}

.pendint {
  background-color:#C8A2C8;
}
  .map {
    height: 500px;
    width: 100%;
  }

  .v-label {
    font-size: 12px;
  }

tr.v-data-table__selected {
    background: rgba(0, 0, 0, 0.24) !important; 
  }

  .vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
  }

  .div-flex {
    display: flex;
  }

  .theme--light.v-messages {
    color: red;
  }

html,body{
    overflow-y: hidden; 
}
</style>
