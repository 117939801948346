import axios from "axios"

export const cps = {
  methods: {
    async getDataCps(){
      return new Promise((resolve, reject)=>{
        let json ={
          c: "CallCenterNewCapen",
          cmd: "getCps",
          exec: "self",
          uid: this.uid,
          hash: this.hash
        };
        axios.get(this.$api_url, { params:json } )
          .then(response => {
            //console.log(response);
            resolve(response.data);
            //this.itemsCps = response.data
          }).catch( error => {
            console.log(error);
            resolve({ success:false, error: error});
        });
      })
    },
    async getDataFromCp(cp){
      //console.log(this.clNuevo.cp);
      return new Promise((resolve, reject)=>{
        let json ={
          c: "CallCenterNewCapen",
          cmd: "getDataCp",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          cp: cp
        };
        axios.get(this.$api_url, { params:json } )
          .then(response => {
            //console.log(response);
            resolve(response.data);
            //this.clNuevo.estado = response.data_edo
          }).catch( error => {
            console.log(error);
            resolve({ success:false, error: error});
        });
      })
    },
    getDataEdos(){
      return new Promise((resolve, reject)=>{
        let json ={
          c: "CallCenterNewCapen",
          cmd: "getDataEdos",
          exec: "self",
          uid: this.uid,
          hash: this.hash
        };
        axios.get(this.$api_url, { params:json } )
          .then(response => {
            //console.log(response);
            resolve(response.data);
            //this.clNuevo.estado = response.data_edo
          }).catch( error => {
            //console.log(error);
            resolve({ success:false, error: error});
        });
      })
    },
    getDataMuns(edo){
      return new Promise((resolve, reject)=>{
        let json ={
          c: "CallCenterNewCapen",
          cmd: "getDataMuns",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          edo: edo
        };
        axios.get(this.$api_url, { params:json } )
          .then(response => {
            //console.log(response);
            resolve(response.data);
            //this.clNuevo.estado = response.data_edo
          }).catch( error => {
            //console.log(error);
            resolve({ success:false, error: error});
        });
      })
    },
    getDataCols(mun){
      return new Promise((resolve, reject)=>{
        let json ={
          c: "CallCenterNewCapen",
          cmd: "getDataCols",
          exec: "self",
          uid: this.uid,
          hash: this.hash,
          mun: mun
        };
        axios.get(this.$api_url, { params:json } )
          .then(response => {
            //console.log(response);
            resolve(response.data);
            //this.clNuevo.estado = response.data_edo
          }).catch( error => {
            //console.log(error);
            resolve({ success:false, error: error});
        });
      })
    },
    addNewCp(json){
      //return new Promise((resolve, reject)=>{
        const queryString = new URLSearchParams(json).toString();
        axios.post(this.$api_url+'?'+queryString)
          .then(response => {
            console.log(response);
            //resolve(response.data);

            //this.clNuevo.estado = response.data_edo
          }).catch( error => {
            console.log(error);
            //resolve({ success:false, error: error});
        });
      //})
    },
    cleanAnyString(val){
      //console.log(id+' '+val);
      val = val.replace(/á/gi,"a");
      val = val.replace(/é/gi,"e");
      val = val.replace(/í/gi,"i");
      val = val.replace(/ó/gi,"o");
      val = val.replace(/ú/gi,"u");
      val = val.replace(/ñ/gi,"n");
      val = val.replace(/Á/gi,"a");
      val = val.replace(/É/gi,"e");
      val = val.replace(/Í/gi,"i");
      val = val.replace(/Ó/gi,"o");
      val = val.replace(/Ú/gi,"u");
      val = val.replace(/Ñ/gi,"n");

      return val.trim()
    }
  },
}